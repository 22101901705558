import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeviceList from "./components/list";
import { fetchDeviceList } from "../../store/device/actions";
import ToastContainer from "../../components/Common/ToastContainer";
import DeviceDeleteConfirmationModal from "./components/delete-confirmation";
import DeviceFormModal from "./components/form";

const DevicesPage = ({ t, fetchDeviceListDispatch }) => {
  //meta title
  document.title = "Machines";

  useEffect(() => {
      fetchDeviceListDispatch();
  }, []);

  return (
    <>
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={t("Machines")}
                />
                <DeviceList />
            </Container>
        </div>
        <DeviceFormModal />
        <DeviceDeleteConfirmationModal />
        <ToastContainer />
    </>
  );
};

DevicesPage.propTypes = {
  t: PropTypes.any,
  fetchDeviceListDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(null,
    (dispatch) => ({
        fetchDeviceListDispatch: bindActionCreators(fetchDeviceList, dispatch),
    }),
))(DevicesPage));
