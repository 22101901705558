import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import Modal from "../../../../components/Common/Modal";
import {
    selectIsDeleteConfirmationModalVisible,
    selectIsDeviceDeleting,
    selectSelectedItem
} from "../../../../store/device/selectors";
import {deleteDevice, toggleDeleteDeviceConfirmation} from "../../../../store/device/actions";


const DeleteConfirmation = ({
    t,
    device,
    isDeleteConfirmationModalVisible,
    isDeleting,
    toggleDeleteDeviceConfirmationDispatch,
    deleteDeviceDispatch,
}) => {
    const onDeleteClick = () => {
        deleteDeviceDispatch({
            userId: 1, // TODO: change to real user id
            deviceSerialNumber: device.serialNumber,
        });
    };

    return (
        <Modal
            isOpen={isDeleteConfirmationModalVisible}
            onToggle={toggleDeleteDeviceConfirmationDispatch}
            title={t("Delete device")}
        >
            <p className="text-muted font-size-16 mb-4">
                Are you sure you want to permanently delete<br/>
                device <b>{device?.machineName} (#{device?.serialNumber})</b>?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={onDeleteClick}
                    disabled={isDeleting}
                >Delete Now</button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleDeleteDeviceConfirmationDispatch}
                >Close</button>
            </div>
        </Modal>
    );
};

DeleteConfirmation.propTypes = {
    t: PropTypes.any,
    isDeleteConfirmationModalVisible: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    device: PropTypes.shape({
        machineName: PropTypes.string.isRequired,
        serialNumber: PropTypes.string.isRequired,
    }),
    toggleDeleteDeviceConfirmationDispatch: PropTypes.func.isRequired,
    deleteDeviceDispatch: PropTypes.func.isRequired,
};

export default withTranslation()(compose(
    connect(createStructuredSelector({
        isDeleteConfirmationModalVisible: selectIsDeleteConfirmationModalVisible(),
        isDeleting: selectIsDeviceDeleting(),
        device: selectSelectedItem(),
    }),
    (dispatch) => ({
        toggleDeleteDeviceConfirmationDispatch: bindActionCreators(toggleDeleteDeviceConfirmation, dispatch),
        deleteDeviceDispatch: bindActionCreators(deleteDevice, dispatch),
    }),
))(DeleteConfirmation));