import { useFormik } from "formik";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import HeaderBox from "../header-box";
import validationSchema from "./validationSchema";
import Input from "../../../../components/Common/Input";
import Select from "../../../../components/Common/Select";
import { STATES_AND_COUNTRIES } from "../../../../common/constants";
import { BUSINESS_TYPES_OPTIONS, MACHINE_APPLICATION_OPTIONS } from "./constants";
import { PROP_TYPE_USER } from "../../../../common/types";

const UserInformationForm = ({ data, user, onNext, onPrev, deviceSerialNumber }) => {

    const form = useFormik({
        initialValues: {
            firstName: data?.firstName || user?.firstName || "",
            lastName: data?.lastName || user?.lastName || "",
            email: data?.email || user?.email || "",
            phoneNumber: data?.phoneNumber || "",
            company: data?.company || user?.company?.name || "",
            businessType: data?.businessType || user?.company?.businessType || "",
            machineApplication: data?.machineApplication  || user?.company?.machineApplication || "",
            address: data?.address || user?.address?.line1 || "",
            address2: data?.address2 || user?.address?.line2 || "",
            city: data?.city || user?.address?.city || "",
            state: data?.state || user?.address?.state || "",
            zip: data?.zip || user?.address?.zip || "",
            country: "",
        },
        validationSchema,
        validateOnMount: true,
        onSubmit: () => {},
    });

    const isCompanyDisabled = useMemo(() => user?.company?.id, [user]);
    const isAddressDisabled = useMemo(() => user?.address?.id, [user]);
    const isButtonDisabled = useMemo(() =>
            Object.keys(form.errors).length
        , [form]);

    //const countries = useMemo(() => COUNTRIES.map(({ name, code }) => ({label: name, value: code})), [COUNTRIES]);
    const states = useMemo(() => STATES_AND_COUNTRIES.map(({ State, StateCode }) => ({ label: State, value: StateCode })), [STATES_AND_COUNTRIES]);

    return (
        <>
            <HeaderBox
                title="User details"
                serialNumber={deviceSerialNumber}
                onPrev={onPrev}
            />

            <Card>
                <CardBody>
                    <Container fluid>
                        <Form
                            onSubmit={e => {
                                e.preventDefault();
                                form.handleSubmit();
                                onNext({
                                    user: form.values,
                                });
                                return false;
                            }}
                        >
                            <Row className="mb-4">
                                <Col xs={12} className="font-size-18 fw-bold pb-3 text-uppercase">
                                    User Information
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="First Name"
                                            name="firstName"
                                            placeholder="First Name"
                                            form={form}
                                            value={form.values.firstName}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Last Name"
                                            name="lastName"
                                            placeholder="Last Name"
                                            form={form}
                                            value={form.values.lastName}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Email Address"
                                            name="email"
                                            placeholder="Email Address"
                                            form={form}
                                            value={form.values.email}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Phone Number"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            form={form}
                                            value={form.values.phoneNumber}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col xs={12} className="font-size-18 fw-bold pb-3 text-uppercase">
                                    Company Information
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Company"
                                            name="company"
                                            placeholder="Company"
                                            form={form}
                                            value={form.values.company}
                                            disabled={isCompanyDisabled}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Select
                                            name="businessType"
                                            label="Business Type"
                                            placeholder="Select Business Type"
                                            options={BUSINESS_TYPES_OPTIONS}
                                            formik={form}
                                            value = {
                                                BUSINESS_TYPES_OPTIONS.filter(option =>
                                                    option.value === form.values.businessType)
                                            }
                                            isDisabled={isCompanyDisabled}
                                        ></Select>
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Select
                                            label="Machine Application"
                                            name="machineApplication"
                                            placeholder="Machine Application"
                                            options={MACHINE_APPLICATION_OPTIONS}
                                            formik={form}
                                            value = {
                                                MACHINE_APPLICATION_OPTIONS.filter(option =>
                                                    option.value === form.values.machineApplication)
                                            }
                                            isDisabled={isCompanyDisabled}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="font-size-18 fw-bold pb-3 text-uppercase">
                                    Address
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Address"
                                            name="address"
                                            placeholder="Address"
                                            form={form}
                                            value={form.values.address}
                                            disabled={isAddressDisabled}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <Input
                                            label="Address 2"
                                            name="address2"
                                            placeholder={isAddressDisabled ? "" : "Address 2"}
                                            form={form}
                                            value={form.values.address2}
                                            disabled={isAddressDisabled}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} xl={3}>
                                    <div className="mb-3">
                                        <Input
                                            label="City"
                                            name="city"
                                            placeholder="City"
                                            form={form}
                                            value={form.values.city}
                                            disabled={isAddressDisabled}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} xl={3}>
                                    <div className="mb-3">
                                        <Select
                                            name="state"
                                            label="State"
                                            placeholder="Select State"
                                            options={states}
                                            formik={form}
                                            value = {
                                                states.filter(option =>
                                                    option.value === form.values.state
                                                )
                                            }
                                            isDisabled={isAddressDisabled}
                                        ></Select>
                                    </div>
                                </Col>
                                <Col xs={6} xl={3}>
                                    <div className="mb-3">
                                        <Input
                                            label="Zipcode"
                                            name="zip"
                                            placeholder="Zipcode"
                                            form={form}
                                            value={form.values.zip}
                                            disabled={isAddressDisabled}
                                        />
                                    </div>
                                </Col>
                                {/*<Col xs={6} xl={3}>
                                    <div className="mb-3">
                                        <Select
                                            name="country"
                                            label="Country"
                                            placeholder="Select Country"
                                            options={countries}
                                            formik={form}
                                            value = {
                                                countries.filter(option =>
                                                    option.value === form.values.country)
                                            }
                                            isDisabled={isAddressDisabled}
                                        ></Select>
                                    </div>
                                </Col>*/}
                            </Row>

                            <Row>
                                <Col>
                                    <div className="text-center mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary save-user"
                                            disabled={isButtonDisabled}
                                        >
                                            SAVE & CONTINUE
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </CardBody>
            </Card>
        </>
    );
};

UserInformationForm.propTypes = {
    user: PropTypes.shape(PROP_TYPE_USER),
    deviceSerialNumber: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
};

UserInformationForm.defaultProps = {
    user: null,
};

export default UserInformationForm;
