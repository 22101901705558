import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { createSelector } from "reselect";
import {Routes, Route, useNavigate} from "react-router-dom";
import { layoutTypes } from "./constants/layout";

// Import all middleware
import AuthMiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

import useRoutesByRole from "./common/hooks/routesByRole";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {
    selectAuthUserDetails,
    selectIsAuthUserDetailsLoading,
    selectIsAuthUserNotRegistered, selectIsRegistrationFailed, selectIsRegistrationInProgress
} from "./store/auth/login/selectors";
import {useEffect} from "react";
import {InteractionStatus} from "@azure/msal-browser";
import {getAuthUserDetails, registerAuthUser} from "./store/auth/login/actions";

const getLayout = (layoutType) => {
    let Layout = VerticalLayout;
    switch (layoutType) {
        case layoutTypes.VERTICAL:
            Layout = VerticalLayout;
            break;
        case layoutTypes.HORIZONTAL:
            Layout = HorizontalLayout;
            break;
        default:
            break;
    }
    return Layout;
};

const App = () => {
    const selectLayoutState = (state) => state.Layout;
    const LayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
        })
    );


    const { layoutType } = useSelector(LayoutProperties);
    const Layout = getLayout(layoutType);


    const {
        publicRoutes,
        authProtectedRoutes,
        authProtectedRoutesForNonRegistered,
    } = useRoutesByRole();

    // Check if we need to get User info or register user
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const { inProgress, accounts } = useMsal();
    const isAuthUserNotRegistered = useSelector(selectIsAuthUserNotRegistered());
    const isAuthUserDetailsLoading = useSelector(selectIsAuthUserDetailsLoading());
    const authUserDetails = useSelector(selectAuthUserDetails());
    const isRegistrationInProgress = useSelector(selectIsRegistrationInProgress());
    const isRegistrationFailed = useSelector(selectIsRegistrationFailed());

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            if (isAuthUserNotRegistered) {
                if (!isRegistrationInProgress && !isRegistrationFailed) {
                    const { idTokenClaims: { email, given_name: firstName, family_name: lastName, name: displayName } } = accounts[0];

                    dispatch(registerAuthUser({
                        email,
                        firstName,
                        lastName,
                        displayName,
                        created: new Date().toISOString(),
                        ownedDevices: [],
                        operatedDevices: [],
                        dealerDevices: [],
                        appRoles: [],
                    }));
                }
            }
            else if (isAuthenticated && !authUserDetails && !isAuthUserDetailsLoading) {
                dispatch(getAuthUserDetails(accounts[0]?.idTokenClaims?.email));
            }
        }

    }, [
        isAuthenticated,
        isAuthUserNotRegistered,
        isAuthUserDetailsLoading,
        inProgress,
        isRegistrationInProgress,
        isRegistrationFailed,
    ]);

    if (isRegistrationInProgress) return 'Finishing registration ...'
    return (
        <Routes>
            {[...publicRoutes, ...authProtectedRoutesForNonRegistered].map((route, idx) => (
                <Route
                    path={route.path}
                    element={
                        <NonAuthLayout>
                            {route.component}
                        </NonAuthLayout>
                    }
                    key={idx}
                    exact={true}
                />
            ))}

            {authProtectedRoutes.map((route, idx) => (
                <Route
                    path={route.path}
                    element={
                        <AuthMiddleware>
                            <Layout>{route.component}</Layout>
                        </AuthMiddleware>}
                    key={idx}
                    exact={true}
                />
            ))}
        </Routes>
    );
};

App.propTypes = {
    layout: PropTypes.any
};

export default App;
