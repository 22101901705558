import { Col, Row } from "reactstrap";
import { PROP_TYPE_USER } from "../../../../common/types";
import UserLink from "../../../../components/Common/UserLink";
import PropTypes from "prop-types";

const OperatorItem = ({ user, isAdmin }) => {
    return (
        <Row>
            <Col><UserLink className="total-count-descr text-primary" user={user} isAdmin={isAdmin} /></Col>
            {/*<Col className="d-flex justify-content-end total-count-descr">1/1/2024</Col>*/}
        </Row>
    );
};

OperatorItem.propTypes = {
    user: PropTypes.shape(PROP_TYPE_USER),
    isAdmin: PropTypes.bool,
};

OperatorItem.defaultProps = {
    isAdmin: false,
};

export default OperatorItem;
