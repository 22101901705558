import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeviceList from "./components/list";
import { fetchMyDeviceList, toggleShowDeviceForm } from "../../store/device/actions";
import ToastContainer from "../../components/Common/ToastContainer";
import DeviceDeleteConfirmationModal from "./components/delete-confirmation";
import DeviceFormModal from "./components/form";
import useAuthUser from "../../common/hooks/authUser";
import { getAuthUserDetails } from "../../store/auth/login/actions";
import { useMsal } from "@azure/msal-react";

const MyDevicesPage = ({
   t,
   fetchMyDeviceListDispatch,
   toggleShowDeviceFormDispatch,
   getAuthUserDetailsDispatch,
}) => {
  //meta title
  document.title = "My Machines";

  const { user, isRegistered } = useAuthUser();
  const { accounts } = useMsal();

  useEffect(() => {
      if (isRegistered) {
          fetchMyDeviceListDispatch(user.id);
      }
  }, [user, isRegistered]);

  useEffect(() => {
      getAuthUserDetailsDispatch(accounts[0]?.idTokenClaims?.email)
  }, []);

  return (
    <>
        <div className="page-content">
            <Container fluid>
                <Container fluid className="d-flex align-content-center flex-row">
                    <Breadcrumbs
                        title={t("My Machines")}
                    >
                        <button
                            className="ms-4 btn btn-primary"
                            onClick={() => toggleShowDeviceFormDispatch()}
                        >REGISTER MACHINE</button>
                    </Breadcrumbs>
                </Container>

                <DeviceList />
            </Container>
        </div>
        <DeviceFormModal />
        <DeviceDeleteConfirmationModal />
        <ToastContainer />
    </>
  );
};

MyDevicesPage.propTypes = {
  t: PropTypes.any,
  fetchMyDeviceListDispatch: PropTypes.func,
  toggleShowDeviceFormDispatch: PropTypes.func,
  getAuthUserDetailsDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(null,
    (dispatch) => ({
        fetchMyDeviceListDispatch: bindActionCreators(fetchMyDeviceList, dispatch),
        toggleShowDeviceFormDispatch: bindActionCreators(toggleShowDeviceForm, dispatch),
        getAuthUserDetailsDispatch: bindActionCreators(getAuthUserDetails, dispatch),
    }),
))(MyDevicesPage));
