import { API_URL } from "../config";

export const getDeviceList = async () => {
    const response = await fetch(`${API_URL}/Device/GetAllDevices`);
    return response.json();
};

export const getDeviceListForUser = async (userId) => {
    const response = await fetch(`${API_URL}/Device/GetDevicesForUser?userId=${encodeURIComponent(userId)}`);
    return response.json();
};

export const getDevice = async (deviceSerialNumber) => {
    const response = await fetch(`${API_URL}/Device/GetDeviceDetail?deviceSerialNumber=${encodeURIComponent(deviceSerialNumber)}`);
    return response.json();
};

export const addDevice = async ({ userId, deviceSerialNumber }) => {
    const response = await fetch(`${API_URL}/Device/AddDevice?userId=${encodeURIComponent(userId)}&deviceSerialNumber=${encodeURIComponent(deviceSerialNumber)}`, {
        method: 'POST',
    });
    return response.json();
};

export const deleteDevice = async ({ userId, deviceSerialNumber }) => {
    const response = await fetch(`${API_URL}/Device/RemoveDevice?userId=${encodeURIComponent(userId)}&deviceSerialNumber=${encodeURIComponent(deviceSerialNumber)}`, {
        method: 'POST',
    });
    return response.json();
};

export const assignDeviceOwner = async ({ userId, deviceSerialNumber }) => {
    const response = await fetch(`${API_URL}/Device/AddDeviceAsOwner?userId=${encodeURIComponent(userId)}&deviceSerialNumber=${encodeURIComponent(deviceSerialNumber)}`, {
        method: 'POST',
    });
    return response.json();
};

export const getDeviceRegistrationStatus = async (deviceSerialNumber) => {
    const response = await fetch(`${API_URL}/Device/RegistrationStatus?deviceSerialNumber=${encodeURIComponent(deviceSerialNumber)}`);
    return response.json();
};
