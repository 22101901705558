import ReactSelect from "react-select";
import {FormFeedback, Label} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

const styles = {
    control: (styles, { theme: {colors: { danger }}, selectProps: { invalid }, isDisabled }) => ({
        ...styles,
        backgroundColor: isDisabled ? '#f8f8fb' : 'white',
        borderColor: invalid ? danger : '#d9d9d9',
    }),
    menuList: styles => ({
        ...styles,
        backgroundColor: 'white',
    }),
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? 'grey' : (isFocused ? '#f8f8fb': 'white'),
            color: '#000',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
        };
    },
    singleValue:  (styles, { isDisabled}) => {
        return {
            ...styles,
            color: '#343a40',
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
};

const Select = ({formik, label, afterLabel, ...props}) => (
    <>
        <Label className="form-label">{label}{afterLabel && (<span className="fw-normal">{afterLabel}</span>)}</Label><br/>
        <ReactSelect
            styles={styles}
            onChange={(e) => formik.setFieldValue(props.name, e.value)}
            onBlur={() => formik.setFieldTouched(props.name, true, true)}
            invalid={!!(formik.touched.userId && formik.errors.userId)}
            {...props}
        />
        {formik.touched[props.name] &&
        formik.errors[props.name] ? (
            <FormFeedback type="invalid" className="d-block">
                {formik.errors[props.name]}
            </FormFeedback>
        ) : null}
    </>
);

Select.propTypes = {
    formik: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        touched: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.string,
    afterLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
};

Select.defaultProps = {
    label: '',
    afterLabel: '',
};

export default Select;
