import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {selectIsAssignOwnerFormSaving, selectIsAssignOwnerFormVisible} from "../../../../store/device/selectors";
import {assignDeviceOwner, toggleAssignOwnerFormVisibility} from "../../../../store/device/actions";
import SelectUserFormModal from "../select-user-form";
import { withTranslation } from "react-i18next";
import { selectUserList } from "../../../../store/user/selectors";
import { fetchUserList } from "../../../../store/user/actions";
import { useEffect } from "react";
import { PROP_TYPE_USER } from "../../../../common/types";

const AssignOwnerForm = ({
    deviceSerialNumber,
    isAssignOwnerFormVisible,
    userList,
    isAssignOwnerFormSaving,
    toggleAssignOwnerFormVisibilityDispatch,
    fetchUserListDispatch,
    assignDeviceOwnerDispatch,
}) => {
    const onSubmit = ({ userId }) => {
        assignDeviceOwnerDispatch({
            userId,
            deviceSerialNumber,
        });
    };

    useEffect(() => {
        if (isAssignOwnerFormVisible) {
            fetchUserListDispatch();
        }
    }, [isAssignOwnerFormVisible]);

    return (
        <SelectUserFormModal
            title="Assign machine owner"
            fieldTitle="Owner"
            isOpen={isAssignOwnerFormVisible}
            onToggle={toggleAssignOwnerFormVisibilityDispatch}
            onSubmit={onSubmit}
            userList={userList}
            isSaving={isAssignOwnerFormSaving}
        />
    );
};

AssignOwnerForm.propTypes = {
    userList: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_USER)).isRequired,
    deviceSerialNumber: PropTypes.string.isRequired,
    isAssignOwnerFormVisible: PropTypes.bool.isRequired,
    isAssignOwnerFormSaving: PropTypes.bool.isRequired,
    toggleAssignOwnerFormVisibilityDispatch: PropTypes.func.isRequired,
    fetchUserListDispatch: PropTypes.func.isRequired,
    assignDeviceOwnerDispatch: PropTypes.func.isRequired,
};

export default withTranslation()(compose(
    connect(createStructuredSelector({
        isAssignOwnerFormVisible: selectIsAssignOwnerFormVisible(),
        userList: selectUserList(),
        isAssignOwnerFormSaving: selectIsAssignOwnerFormSaving(),
    }),
    (dispatch) => ({
        toggleAssignOwnerFormVisibilityDispatch: bindActionCreators(toggleAssignOwnerFormVisibility, dispatch),
        fetchUserListDispatch: bindActionCreators(fetchUserList, dispatch),
        assignDeviceOwnerDispatch: bindActionCreators(assignDeviceOwner, dispatch),
    }),
))(AssignOwnerForm));
