// @flow
import { takeEvery, put, call } from "redux-saga/effects";
import { GET_USER, GET_USER_LIST } from "./actionTypes";
import { toast } from "react-toastify";
import {
  setUserFail,
  setUserListFail,
  setUserListSuccess, setUserSuccess,
} from "./actions";
import { getUser, getUserList } from "../../api/user";

function* onFetchUserList() {
  try {
    const response = yield call(getUserList);
    yield put(setUserListSuccess(response));
  } catch (error) {
    yield put(setUserListFail(error));
    toast.error("Cannot fetch user list", {autoClose: 2000});
  }
}

function* onFetchUserDetails({ payload: userId }) {
  try {
    const response = yield call(getUser, userId);
    yield put(setUserSuccess(response));
  } catch (error) {
    yield put(setUserFail(error));
    toast.error("Cannot fetch user details", {autoClose: 2000});
  }
}

export function* UserSaga() {
  yield takeEvery(GET_USER_LIST, onFetchUserList);
  yield takeEvery(GET_USER, onFetchUserDetails);
}

export default UserSaga;
