import {createSelector} from "reselect";
import { INIT_STATE } from "./reducer";

export const selectDeviceDomain = (state) => state.Device || INIT_STATE;

export const selectDeviceList = () =>
    createSelector(selectDeviceDomain, ({ list }) => list);

export const selectIsDeviceListLoading = () =>
    createSelector(selectDeviceDomain, ({ isListLoading }) => isListLoading);

export const selectIsDeviceFormVisible = () =>
    createSelector(selectDeviceDomain, ({ isFormVisible }) => isFormVisible);

export const selectIsDeviceFormSaving = () =>
    createSelector(selectDeviceDomain, ({ isFormSaving }) => isFormSaving);

export const selectIsDeleteConfirmationModalVisible = () =>
    createSelector(selectDeviceDomain, ({ isDeleteConfirmationModalVisible }) => isDeleteConfirmationModalVisible);

export const selectSelectedItem = () =>
    createSelector(selectDeviceDomain, ({ selectedItem }) => selectedItem);

export const selectIsSelectedItemLoading = () =>
    createSelector(selectDeviceDomain, ({ isLoading }) => isLoading);

export const selectIsDeviceDeleting = () =>
    createSelector(selectDeviceDomain, ({ isItemDeleting }) => isItemDeleting);

export const selectIsAssignOwnerFormVisible = () =>
    createSelector(selectDeviceDomain, ({ isAssignOwnerFormVisible }) => isAssignOwnerFormVisible);

export const selectIsAssignOwnerFormSaving = () =>
    createSelector(selectDeviceDomain, ({ isAssignOwnerFormSaving }) => isAssignOwnerFormSaving);

export const selectIsRegistrationStatusLoading = () =>
    createSelector(selectDeviceDomain, ({ isRegistrationStatusLoading }) => isRegistrationStatusLoading);

export const selectDeviceRegistrationData = () =>
    createSelector(selectDeviceDomain, ({ deviceRegistrationData }) => deviceRegistrationData);

export const selectIsRegistrationLoading = () =>
    createSelector(selectDeviceDomain, ({ isRegistrationLoading }) => isRegistrationLoading);
