import {
  GET_DASHBOARD_DETAILS,
  SET_DASHBOARD_DETAILS_FAIL,
  SET_DASHBOARD_DETAILS_SUCCESS,
} from "./actionTypes";

export const fetchDashboardDetails = () => ({
  type: GET_DASHBOARD_DETAILS,
});

export const setDashboardDetailsSuccess = ({ data }) => ({
  type: SET_DASHBOARD_DETAILS_SUCCESS,
  payload: data,
});

export const setDashboardDetailsFail = () => ({
  type: SET_DASHBOARD_DETAILS_FAIL
});
