import { useEffect } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import UserList from "./components/list";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { fetchUserList } from "../../store/user/actions";

const UsersPage = ({ t, fetchUserListDispatch }) => {
    document.title = "Users";

    useEffect(() => {
        fetchUserListDispatch();
    }, []);
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title={t("Users")}
                />
                <UserList />
            </Container>
        </div>
    );
};

UsersPage.propTypes = {
    t: PropTypes.any,
    fetchUserListDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(null,
    (dispatch) => ({
        fetchUserListDispatch: bindActionCreators(fetchUserList, dispatch),
    }),
))(UsersPage));
