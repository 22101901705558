// @flow
import {
  GET_USER_LIST,
  GET_USER,
  SET_USER_LIST_SUCCESS,
  SET_USER_LIST_FAIL,
  SET_USER_SUCCESS,
  SET_USER_FAIL,
} from "./actionTypes";

export const INIT_STATE = {
  list: [],
  isListLoading: false,
  isLoading: false,
  selectedItem: null,
};

const User = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        list: [],
        isListLoading: true,
      };
    case SET_USER_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload?.data,
        isListLoading: false,
      };
    case SET_USER_LIST_FAIL:
      return {
        ...state,
        list: [],
        isListLoading: false,
      };
    case GET_USER:
      return {
        ...state,
        selectedItem: null,
        isLoading: true,
      };
    case SET_USER_SUCCESS:
      return {
        ...state,
        selectedItem: action.payload?.data,
        isLoading: false,
      };
    case SET_USER_FAIL:
      return {
        ...state,
        selectedItem: null,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default User;
