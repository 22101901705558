import { Spinner as ReactstrapSpinner } from "reactstrap";

const Spinner = () => {
    return (
        <ReactstrapSpinner
            color="primary"
            className='position-absolute'
            style={{ top: 'calc(50% - 13px)', left: 'calc(50% - 13px)'}}
        />
    );
};

export default Spinner;
