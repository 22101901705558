import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../../../../components/Common/Modal";
import SelectUserForm from "./form";
import { PROP_TYPE_USER } from "../../../../common/types";

const SelectUserFormModal = ({
    t,
    title,
    isOpen,
    userList,
    fieldTitle,
    isSaving,
    onToggle,
    onSubmit,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onToggle={onToggle}
            title={t(title)}
        >
            <SelectUserForm
                fieldTitle={fieldTitle}
                userList={userList}
                isSaving={isSaving}
                onSubmit={onSubmit}
                onClose={onToggle}
            />
        </Modal>
    );
};

SelectUserFormModal.propTypes = {
    t: PropTypes.func,
    title: PropTypes.string.isRequired,
    fieldTitle: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    userList: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_USER)).isRequired,
    isSaving: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(SelectUserFormModal);
