import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Container, Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import UsersWidget from "./components/users-widget";
import MachinesWidget from "./components/machines-widget";
import { bindActionCreators, compose} from "redux";
import { connect} from "react-redux";
import { fetchDashboardDetails} from "../../store/dashboard/actions";
import { selectDashboardDetails, selectDashboardIsLoading } from "../../store/dashboard/selectors";
import { createStructuredSelector } from "reselect";
import { PROP_TYPE_MACHINE, PROP_TYPE_USER } from "../../common/types";

const DashboardPage = ({
  t,
  data,
  isLoading,
  fetchDashboardDetailsDispatch
}) => {
  //meta title
  document.title = "Dashboard";

  useEffect(() => {
    fetchDashboardDetailsDispatch();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={t("Dashboard")}
          />
        </Container>
        <div className="p-2">
          <Container fluid>
            <Row>
              <Col lg={12} xl={6}>
                <UsersWidget
                    totalCount={data?.allUserCount}
                    recentUsers={data?.recentUsers}
                    isLoading={isLoading}
                />
              </Col>
              <Col lg={12} xl={6}>
                <MachinesWidget
                    totalCount={data?.allDeviceCount}
                    recentMachines={data?.recentDevices}
                    isLoading={isLoading}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

DashboardPage.propTypes = {
  t: PropTypes.any,
  fetchDashboardDetailsDispatch: PropTypes.func,
  data: PropTypes.shape({
    allDeviceCount: PropTypes.number.isRequired,
    allUserCount: PropTypes.number.isRequired,
    recentDevices: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_MACHINE)).isRequired,
    recentUsers: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_USER)).isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default withTranslation()(compose(
  connect(createStructuredSelector({
      data: selectDashboardDetails(),
      isLoading: selectDashboardIsLoading(),
  }),
      (dispatch) => ({
      fetchDashboardDetailsDispatch: bindActionCreators(fetchDashboardDetails, dispatch),
  }),
))(DashboardPage));
