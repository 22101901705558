import { useMemo } from "react";
import useAuthUser from "./authUser";
import {
    authProtectedRoutesForAdmin,
    authProtectedRoutesForUnregistered,
    authProtectedRoutesForUser, pageNotFoundRoute, publicRoutes
} from "../../routes";

const useRoutesByRole = () => {
    const { isAuthUserNotRegistered, isAdmin, isUser } = useAuthUser();

    const authProtectedRoutes = useMemo(() => {
        if (isAdmin) {
            return authProtectedRoutesForAdmin;
        } else if (isUser) {
            return authProtectedRoutesForUser;
        } else if (isAuthUserNotRegistered) {
            return authProtectedRoutesForUnregistered;
        }

        return [pageNotFoundRoute];
    }, [isAuthUserNotRegistered, isAdmin, isUser]);

    const authProtectedRoutesForNonRegistered = useMemo(() => {
        if (isAuthUserNotRegistered) {
            return authProtectedRoutesForUnregistered;
        }

        return [];
    }, [isAuthUserNotRegistered, isAdmin, isUser]);

    return {
        publicRoutes,
        authProtectedRoutes,
        authProtectedRoutesForNonRegistered,
    };
};

export default useRoutesByRole;
