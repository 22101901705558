import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import DeviceSaga from "./device/saga";
import DashboardSaga from "./dashboard/saga";
import UserSaga from "./user/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(UserSaga),
    fork(DeviceSaga),
    fork(DashboardSaga),
  ]);
}
