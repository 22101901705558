import * as Yup from "yup";

export default Yup.object({
    firstName: Yup.string().required("Please enter First Name"),
    lastName: Yup.string().required("Please enter Last Name"),
    email: Yup.string().required("Please enter Email Address"),
    company: Yup.string().required("Please enter Company"),
    businessType: Yup.string().required("Please enter Business Type"),
    machineApplication: Yup.string().required("Please enter Machine Application"),
    address: Yup.string().required("Please enter Address"),
    city: Yup.string().required("Please enter City"),
    state: Yup.string().required("Please select State"),
    zip: Yup.string().required("Please enter Zip"),
    //country: Yup.string().required("Please select Country"),
});
