import PropTypes from "prop-types";

export const PROP_TYPE_MACHINE = {
    id: PropTypes.number.isRequired,
    serialNumber: PropTypes.string.isRequired,
    machineName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    lastActive: PropTypes.string.isRequired,
    imgUrl: PropTypes.string,
};

export const PROP_TYPE_MACHINE_TELEMETRY_DATA = {
    maxPressureBar: PropTypes.number,
    motorOut1MeasuredMA: PropTypes.number,
    motorPressureRaw: PropTypes.number,
    motorRotorSprocketTeeth: PropTypes.number,
    motorSpeedRaw: PropTypes.number,
    motorSprocketTeeth: PropTypes.number,
    motorSizeCC: PropTypes.number,
    motorTargetMA: PropTypes.number,
    oilTemperatureC: PropTypes.number,
    toolHours: PropTypes.number,
    toolMinutes: PropTypes.number,
    toolSpeedActualRPM: PropTypes.number,
    flowRate: PropTypes.number,
    avgHydraulicPressure: PropTypes.number,
};

export const PROP_TYPE_MACHINE_WITH_DETAILS = {
    ...PROP_TYPE_MACHINE,
    telemetryData: PropTypes.shape(PROP_TYPE_MACHINE_TELEMETRY_DATA).isRequired,
};
