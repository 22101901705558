import { createSelector } from "reselect";
import { INIT_STATE } from "./reducer";

export const selectAuthUserDomain = (state) => state.Login || INIT_STATE;

export const selectAuthUserDetails = () =>
    createSelector(selectAuthUserDomain, ({ authUser }) => authUser);

export const selectIsAuthUserDetailsLoading = () =>
    createSelector(selectAuthUserDomain, ({ isLoading }) => isLoading);

export const selectIsAuthUserNotRegistered = () =>
    createSelector(selectAuthUserDomain, ({ isNotRegisteredUser }) => isNotRegisteredUser);

export const selectIsRegistrationInProgress = () =>
    createSelector(selectAuthUserDomain, ({ isRegistrationInProgress }) => isRegistrationInProgress);

export const selectIsRegistrationFailed = () =>
    createSelector(selectAuthUserDomain, ({ isRegistrationFailed }) => isRegistrationFailed);
