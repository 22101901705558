import PropTypes from "prop-types";

const Cell = ({
    value,
    isLoading,
    loadingValue,
    defaultValue,
    unit,
    suffix,
    isFloatNumber,
}) => {
    if (isLoading) {
        return loadingValue;
    }

    const formattedUnit = unit ? ` ${unit}` : '';
    const formattedValue = value && isFloatNumber ? value.toFixed(2): value;

    return (
        <>
            {value ? `${formattedValue}${formattedUnit}` : defaultValue}
            {(value && suffix) ? <sup>{suffix}</sup> : ''}
        </>
    );
};

Cell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    unit: PropTypes.string,
    suffix: PropTypes.string,
    defaultValue: PropTypes.string,
    loadingValue: PropTypes.string,
    isFloatNumber: PropTypes.bool,
};

Cell.defaultProps = {
    value: undefined,
    isLoading: false,
    unit: undefined,
    suffix: undefined,
    defaultValue: '-',
    loadingValue: '?',
    isFloatNumber: false,
};

export default Cell;
