import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";

const Map = ({ children, defaultZoom, ...otherProps }) => {
    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_CLIENT_ID }}
            defaultZoom={defaultZoom}
            {...otherProps}
        >
            {children}
        </GoogleMapReact>
    );
};

Map.propTypes = {
  defaultZoom: PropTypes.number,
  children: PropTypes.node,
};

Map.defaultProps = {
    defaultZoom: 11,
    children: null,
};

export default Map;
