import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import { COLUMNS } from "../../config";

const UserLinkedMachineList = ({ data }) => {

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardHeader>
                        <CardTitle className="font-size-24">Linked Machines</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <TableContainer
                            columns={COLUMNS}
                            data={data || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search..."
                            isCustomPageSize={true}
                            isAddButton={false}
                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

UserLinkedMachineList.propTypes = {
    data: PropTypes.array,
};

export default UserLinkedMachineList;
