import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { PROP_TYPE_MACHINE_WITH_DETAILS } from "../../../../common/types";
import { getFormattedDate } from "../../../../helpers/date_time_helper";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import deviceDefaultImageSvg from "../../../../assets/images/device-default-image.svg";
import gearIconSvg from "../../../../assets/svg/gear-icon.svg";
import listIconSvg from "../../../../assets/svg/list-icon.svg";

import { NO_DATE } from "../../../../common/constants";
import Separator from "../../../../components/Common/Separator";
import Cell from "../../../../components/Common/Cell";

import './styles.scss';
import UNIT from "../../../../constants/unit";
import Spinner from "../../../../components/Common/Spinner";
import ScreenOverlay from "../../../../components/Common/ScreenOverlay";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { toggleAssignOwnerFormVisibility } from "../../../../store/device/actions";
import AssignOwnerForm from "../assign-owner-form";
import OperatorItem from "../operator-item";
import UserLink from "../../../../components/Common/UserLink";

const FAKE_DATA = {
  registeredDate: '2024-05-02T12:46:29.78',
  dealerDisplayName: 'Dealer Smith',
};

import Map from "../../../../components/Common/Map";
import MapMarker from "../../../../components/Common/Map/MapMarker";

const DeviceDetails = ({ data, isLoading, isAdmin, toggleAssignOwnerFormVisibilityDispatch }) => {
    const formattedRegisteredDate = useMemo(() => getFormattedDate(FAKE_DATA.registeredDate, "M/D/YYYY"), [data?.created]);
    const formattedLastActiveDate = useMemo(() => data?.lastActive ? (data?.lastActive === NO_DATE ? '-' : getFormattedDate(data?.lastActive, "M/D/YYYY")) : '', [data?.lastActive]);
    const totalHours = (data?.telemetryData.toolHours || data?.telemetryData.toolMinutes) ?  `${data?.telemetryData.toolHours || 0} H ${data?.telemetryData.toolMinutes || 0} M` : '-';

    const operators = data?.operators.map((user) => (
        <OperatorItem key={`operator-${user.id}`} user={user} isAdmin={isAdmin} />
    ));

    const dealer = data?.dealers[0];
    const owner = data?.owners[0];

    const hasLastKnownLocation = useMemo(() => data?.telemetryData?.latitude && data?.telemetryData?.longitude, [data?.telemetryData]);

    return (
        <>
            <Container fluid className="device-details">
                <Row>
                    <Col sm="3"  xxl="3" className="d-flex flex-column">
                        {data?.imgUrl
                            ? (<img src={data?.imgUrl} width="100%" />)
                            : (<div className="h-100 d-flex flex-column justify-content-center align-items-center text-muted">
                                No image
                              </div>)
                        }
                    </Col>
                    <Col sm="3" xxl="3" className="d-flex flex-column">
                        <p>
                            <span className="field-title">Serial Number:</span><br/>
                            <span className="field-value text-capitalize">{data?.serialNumber || `?`}</span>
                        </p>

                        <p>
                            <span className="field-title">Machine Type:</span><br/>
                            <span className="field-value text-capitalize">{data?.type || '?'}</span>
                        </p>

                        <p>
                            <span className="field-title">Date Last Active:</span><br/>
                            <span className="field-value text-capitalize">{formattedLastActiveDate || '?'}</span>
                        </p>
                    </Col>
                    <Col sm="3" xxl="3" className="d-flex flex-column">
                        <p>
                            <span className="field-title">Date Registered:</span><br/>
                            <span className="field-value">{isLoading ? '?' : formattedRegisteredDate}</span>
                        </p>

                        <p>
                            <span className="field-title">Owner:</span><br/>
                            <span className="field-value">
                                {isLoading ? '?' : (
                                    owner
                                        ? <UserLink user={owner} withArrow className="text-black" isAdmin={isAdmin} />
                                        : ("-"
                                            /*<button
                                                className="btn btn-primary"
                                                onClick={toggleAssignOwnerFormVisibilityDispatch}
                                            >Assign Owner</button>*/
                                        )
                                )}
                            </span>
                        </p>

                        <p>
                            <span className="field-title">Dealer:</span><br/>
                            <span className="field-value">{isLoading
                                ? '?'
                                : (dealer
                                    ? <UserLink user={dealer} withArrow className="text-black" isAdmin={isAdmin} />
                                    : '-'
                                )}
                            </span>
                        </p>
                    </Col>

                    <Col sm="3" xxl="3" className="d-flex flex-column">
                        <p>
                            <a href={`https://fecon.app.documoto.com/ui/search?q=%5b${data?.serialNumber}%5d&exact=true&username=FeconGuest&password=N%407%3CU%26qp`} className="btn btn-outline-secondary" target="blank" rel='noopener noreferrer'>
                                <img
                                    src={gearIconSvg}
                                    className="me-2"
                                />
                                Parts & Accessories
                            </a>
                        </p>

                        <p>
                            <a href={`https://fecon.app.documoto.com/ui/search?q=%5b${data?.serialNumber}%5d&exact=true&username=FeconGuest&password=N%407%3CU%26qp`} className="btn btn-outline-secondary" target="blank" rel='noopener noreferrer'>
                                <img
                                    src={listIconSvg}
                                    className="me-2"
                                />
                                Manuals
                            </a>
                        </p>
                    </Col>
                </Row>
            </Container>

            <Separator />

            <Container fluid className="device-details-metrics">
                <Row>
                    <Col xs={12} md={6} xl={3}>
                        <Card>
                            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.avgHydraulicPressure}
                                        isFloatNumber
                                        isLoading={isLoading}
                                    /></CardText>
                                <CardText className="total-count-descr">Avg Hydraulic Pressure</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Card>
                            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.toolSpeedActualRPM}
                                        isFloatNumber
                                        isLoading={isLoading}
                                    /></CardText>
                                <CardText className="total-count-descr">Avg Motor RPM</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Card>
                            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.flowRate}
                                        isFloatNumber
                                        isLoading={isLoading}
                                    /></CardText>
                                <CardText className="total-count-descr">Avg Carrier Flow</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Card>
                            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.oilTemperatureC}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Avg Oil Temperature</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="pb-4">
                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>Last Known Location</CardTitle>
                            </CardHeader>

                            <CardBody>
                                {hasLastKnownLocation ? (
                                    <div style={{height: '500px'}}>
                                        <Map defaultCenter={{lat: data?.telemetryData?.latitude, lng: data?.telemetryData?.longitude}}>
                                            <MapMarker
                                                lat={data?.telemetryData?.latitude}
                                                lng={data?.telemetryData?.longitude}
                                            />
                                        </Map>
                                    </div>
                                ): <div className="total-count mb-0">{isLoading ? "?" : "-"}</div>}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} className="d-flex flex-column">
                        <Card className="">
                            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                                <CardText className="total-count mb-0">{totalHours}</CardText>
                                <CardText className="total-count-descr">Machine Usage</CardText>
                            </CardBody>
                        </Card>

                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>Machine Operators</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Container fluid>
                                    {!isLoading && (
                                        operators?.length
                                            ? operators
                                            : (
                                                <Row>
                                                    <Col className="total-count-descr">-</Col>
                                                </Row>
                                            )
                                    )}
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="device-details-metrics">
                <Row>
                    <Col md={4} className="pb-4">
                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>Mulcher Diagnostics</CardTitle>
                            </CardHeader>
                            <CardBody className="d-flex flex-column diagnostics-box">
                                {/*<CardText className="total-count mb-0">100cc</CardText>
                                <CardText className="total-count-descr">Mulcher Displacement</CardText>*/}

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorSizeCC}
                                        unit={UNIT.CC}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Motor Displacement</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorSprocketTeeth}
                                        unit={UNIT.TEETH}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Motor Sprocket Size</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorRotorSprocketTeeth}
                                        unit={UNIT.TEETH}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Rotor Sprocket Size</CardText>

                                {/*<CardText className="total-count mb-0">DCR</CardText>
                                <CardText className="total-count-descr">Rotor Type</CardText>

                                <CardText className="total-count mb-0">750 mA</CardText>
                                <CardText className="total-count-descr">RPM Setpoint</CardText>*/}

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorTargetMA}
                                        unit={UNIT.MA}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr mb-0">Max RPM mA Command</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="pb-4">
                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>System Diagnostics</CardTitle>
                            </CardHeader>
                            <CardBody className="d-flex flex-column diagnostics-box h-100">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.maxPressureBar}
                                        unit={UNIT.PSI}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Max System PSI</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.flowRate}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Avg Current Flow</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.toolSpeedActualRPM}
                                        isFloatNumber
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Avg Calculated Rotor Speed</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.avgHydraulicPressure}
                                        unit={UNIT.PSI}
                                        isFloatNumber
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Avg Calculated PSI</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4} className="pb-4">
                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>Controller Diagnostics</CardTitle>
                            </CardHeader>
                            <CardBody className="d-flex flex-column diagnostics-box h-100">
                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorSpeedRaw}
                                        isFloatNumber
                                        unit={UNIT.HZ}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">Speed Raw Value Avg</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorPressureRaw}
                                        isFloatNumber
                                        unit={UNIT.MV}
                                        isLoading={isLoading}
                                    />
                                </CardText>
                                <CardText className="total-count-descr">PSI Raw Value Avg</CardText>

                                <CardText className="total-count mb-0">
                                    <Cell
                                        value={data?.telemetryData.motorOut1MeasuredMA}
                                        isFloatNumber
                                        unit={UNIT.MA}
                                        isLoading={isLoading}
                                    />
                                </CardText>

                                <CardText className="total-count-descr mb-0">Mulcher Proportional Output Avg</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {isLoading && (
                <>
                    <ScreenOverlay />
                    <Spinner />
                </>
            )}
            {data?.serialNumber && (
                <AssignOwnerForm deviceSerialNumber={data?.serialNumber} />
            )}
        </>
    );
};

DeviceDetails.propTypes = {
    data: PropTypes.shape(PROP_TYPE_MACHINE_WITH_DETAILS),
    isLoading: PropTypes.bool,
    isAdmin: PropTypes.bool,
    toggleAssignOwnerFormVisibilityDispatch: PropTypes.func.isRequired,
};

DeviceDetails.defaultProps = {
    data: null,
    isLoading: false,
    isAdmin: false,
};

export default compose(
    connect(null,
        (dispatch) => ({
        toggleAssignOwnerFormVisibilityDispatch: bindActionCreators(toggleAssignOwnerFormVisibility, dispatch),
    }),
)(DeviceDetails));
