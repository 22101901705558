import { useState } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../../../../components/Common/Modal";
import DeviceForm from "./form";
import { getDeviceRegistrationStatus, registerMyDevice, toggleShowDeviceForm } from "../../../../store/device/actions";
import {
    selectIsDeviceFormSaving,
    selectIsDeviceFormVisible, selectIsRegistrationStatusLoading
} from "../../../../store/device/selectors";
import { useNavigate } from "react-router-dom";
import { MACHINE_REGISTRATION_STATUS } from "../../../../constants/machine-registration-status";

const DeviceFormModal = ({
    t,
    isFormVisible,
    isFormSaving,
    isRegistrationStatusLoading,
    toggleShowDeviceFormDispatch,
    getDeviceRegistrationStatusDispatch,
}) => {
    const navigate = useNavigate();
    const [error, setError] = useState();

    const onSaveDevice = (values) => {
        setError('');

        const deviceSerialNumber = values.name;
        getDeviceRegistrationStatusDispatch({
            deviceSerialNumber,
            onSuccess: (data) => {
                if (!data?.isValidSerialNumber) {
                    setError('The serial number you have entered is invalid');
                } else if (data?.isRegistered) {
                    setError('This machine is already registered');
                } else {
                    toggleShowDeviceFormDispatch();
                    navigate(`${values.name}/warranty-registration`);
                }
            },
        });
    };

    return (
        <Modal
            isOpen={isFormVisible}
            onToggle={toggleShowDeviceFormDispatch}
            title={t("Register Machine")}
        >
            <DeviceForm
                onSubmit={onSaveDevice}
                isSaving={isFormSaving || isRegistrationStatusLoading}
                error={error}
            />
        </Modal>
    );
};

DeviceFormModal.propTypes = {
    t: PropTypes.func.isRequired,
    isFormVisible: PropTypes.bool.isRequired,
    isFormSaving: PropTypes.bool.isRequired,
    isRegistrationStatusLoading: PropTypes.bool.isRequired,
    toggleShowDeviceFormDispatch: PropTypes.func.isRequired,
    registerMyDeviceDispatch: PropTypes.func.isRequired,
    getDeviceRegistrationStatusDispatch: PropTypes.func.isRequired,
};

export default withTranslation()(compose(
    connect(createStructuredSelector({
        isFormVisible: selectIsDeviceFormVisible(),
        isFormSaving: selectIsDeviceFormSaving(),
        isRegistrationStatusLoading: selectIsRegistrationStatusLoading(),
    }),
    (dispatch) => ({
        toggleShowDeviceFormDispatch: bindActionCreators(toggleShowDeviceForm, dispatch),
        registerMyDeviceDispatch: bindActionCreators(registerMyDevice, dispatch),
        getDeviceRegistrationStatusDispatch: bindActionCreators(getDeviceRegistrationStatus, dispatch),
    }),
))(DeviceFormModal));
