import { API_URL } from "../config";

export const getRegistrationStatus = async (serialNumber) => {
    const response = await fetch(`${API_URL}/Registration/GetRegistrationStatus?serialNumber=${encodeURIComponent(serialNumber)}`);
    return response.json();
};

export const submitRegistration = async (payload) => {
    const response = await fetch(`${API_URL}/Registration/SubmitRegistration`, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    });
    return response.json();
};
