import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import withRouter from "../../components/Common/withRouter";
import useAuthUser from "../../common/hooks/authUser";

const UserProfile = () => {
  //meta title
  document.title = "Profile";

  const { user } = useAuthUser();

  return (
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profile" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{user?.displayName}</h5>
                        <p className="mb-1">Email: {user?.email}</p>
                        <p className="mb-0">ID: {user?.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
  );
};

export default withRouter(UserProfile);
