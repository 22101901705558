import { USER_MACHINE_ROLE } from "../../constants/user-machine-role";

const addRoleToMachineList = (list, role) => {
    return list.map(item => ({
        ...item,
        role,
    }));
};

export const getAllUserRelatedMachines = (data) => {
    let arr = [];

    if (data) {
        arr = [
            ...addRoleToMachineList(data.dealerDevices, USER_MACHINE_ROLE.DEALER),
            ...addRoleToMachineList(data.ownedDevices, USER_MACHINE_ROLE.OWNER),
            ...addRoleToMachineList(data.operatedDevices, USER_MACHINE_ROLE.OPERATOR)
        ];
    }

    return arr;
};
