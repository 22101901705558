import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ToastContainer from "../../components/Common/ToastContainer";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Warranty from "./components/warranty";
import UserInformationForm from "./components/user-information-form";
import DeviceInformationForm from "./components/device-information-form";
import { useNavigate, useParams } from "react-router-dom";
import useAuthUser from "../../common/hooks/authUser";
import { bindActionCreators, compose } from "redux";
import { registerMyDevice } from "../../store/device/actions";
import { selectIsRegistrationLoading } from "../../store/device/selectors";

const DeviceWarrantyRegistrationPage = ({
    isRegistrationLoading,
    registerMyDeviceDispatch,
}) => {
    let navigate = useNavigate();
    const { id } = useParams();
    const [step, setStep] = useState(1);
    const { user } = useAuthUser();
    const [formData, setFormData] = useState({});
    const onNext = (data) => {
        if (data) {
            setFormData({
                ...formData,
                ...data,
            });
        }

        setStep((prev) => prev + 1);
    };
    const onPrev = (data) => {
        if (data) {
            setFormData({
                ...formData,
                ...data,
            });
        }
        setStep((prev) => prev - 1);
    };
    const onSubmit = (data) => {
        const finalFormData = {
            ...formData,
            ...data,
        };

        registerMyDeviceDispatch({
            payload: {
                userDetails: {
                    user: {
                        email: user.email,
                        firstName: finalFormData.user.firstName,
                        lastName: finalFormData.user.lastName,
                        phone: finalFormData.user.phoneNumber,
                    },
                    company: {
                        name: finalFormData.user.company,
                        businessType: finalFormData.user.businessType,
                        machineApplication: finalFormData.user.machineApplication,
                    },
                    address: {
                        line1: finalFormData.user.address,
                        line2: finalFormData.user.address2,
                        city: finalFormData.user.city,
                        state: finalFormData.user.state,
                        zip: finalFormData.user.zip,
                        country: finalFormData.user.country,
                    }
                },
                deviceDetailsDTO: {
                    serialNumber: id,
                    deliveryDate: new Date(finalFormData.device.deliveryDate).toISOString(),
                    hourMeter: finalFormData.device.hourMeter === '' ? undefined : finalFormData.device.hourMeter,
                    saleCondition: finalFormData.device.isNew ? "New" : "Used",
                    hadTransportDamage: finalFormData.device.hasShippingOrTransportDamage,
                    hadCompleteToolKit: finalFormData.device.isToolKitComplete === '' ? undefined : finalFormData.device.isToolKitComplete,
                    hadSafetyInstructions: finalFormData.device.hasManual,
                    hadOrderedOptions: finalFormData.device.allOptionsInstalled,
                    hadDealerMaintenanceReview: finalFormData.device.dealerReviewMaintenanceProcedures,
                    hadDealerSafetyReview: finalFormData.device.dealerReviewSafetyProcedures,
                }
            },

            onSuccess: () => {
               navigate('/machines');
            }
        });
    };

    return (
        <>
            <div className="page-content">
                {step === 1 && (<Warranty onNext={onNext} deviceSerialNumber={id} />)}
                {step === 2 && (
                    <UserInformationForm
                        onNext={onNext}
                        onPrev={onPrev}
                        deviceSerialNumber={id}
                        user={user}
                        data={formData?.user}
                    />
                )}
                {step === 3 && (
                    <DeviceInformationForm
                        onNext={onSubmit}
                        onPrev={onPrev}
                        deviceSerialNumber={id}
                        data={formData?.device}
                        isRegistrationLoading={isRegistrationLoading}
                    />
                )}
            </div>
            <ToastContainer />
        </>
    );
};

DeviceWarrantyRegistrationPage.propTypes = {
    t: PropTypes.any,
    isRegistrationLoading: PropTypes.bool.isRequired,
    registerMyDeviceDispatch: PropTypes.func.isRequired,
};

export default withTranslation()(compose(
    connect(createStructuredSelector({
        isRegistrationLoading: selectIsRegistrationLoading(),
    }),
    (dispatch) => ({
        registerMyDeviceDispatch: bindActionCreators(registerMyDevice, dispatch),
    }),
))(DeviceWarrantyRegistrationPage));
