import PropTypes from "prop-types";
import { Form, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import { useMemo } from "react";
import validationSchema from "./validationSchema";
import Select from "../../../../components/Common/Select";
import { PROP_TYPE_USER } from "../../../../common/types";

const SelectUserForm = ({
    userList,
    fieldTitle,
    isSaving,
    onSubmit,
    onClose,
}) => {
    const formik = useFormik({
        initialValues: {
            userId: undefined
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit,
    });

    const isButtonDisabled = useMemo(() =>
        isSaving || !Object.keys(formik.touched).length || Object.keys(formik.errors).length
    , [formik]);

    const options = useMemo(() => userList.map(({id, displayName}) => ({ value: id, label: displayName})), [userList]);

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
                return false;
            }}
        >
            <FormGroup>
                <Select
                    name="userId"
                    type="select"
                    label={fieldTitle}
                    placeholder="Select user"
                    options={options}
                    formik={formik}
                />
            </FormGroup>


            <div className="hstack gap-2 justify-content-center mb-0">
                <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={isButtonDisabled}
                >Assign</button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                >Close</button>
            </div>
        </Form>
    );
};

SelectUserForm.propTypes = {
    userList: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_USER)).isRequired,
    fieldTitle: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SelectUserForm;
