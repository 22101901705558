import { useMemo } from "react";
import PropTypes from "prop-types";
import { PROP_TYPE_USER } from "../../../../common/types";
import { getFormattedDate } from "../../../../helpers/date_time_helper";
import { Col, Container, Row } from "reactstrap";
import './styles.scss';
import Separator from "../../../../components/Common/Separator";

const UserDetails = ({ data }) => {
    const formattedCreatedDate = useMemo(() => getFormattedDate(data?.created, "M/D/YYYY"), [data?.created]);

    return (
        <>
            <Container fluid className="user-details">
                <Row>
                    <Col md={6} lg={4} xl={3} className="d-flex flex-column align-items-center mb-4">
                        <div className="avatar"></div>
                        <div className="created mt-3">User Since {formattedCreatedDate}</div>
                    </Col>
                    <Col className="d-flex flex-column">
                        <h2 className="my-5">{data?.displayName}</h2>

                        <p>
                            <span className="field-title">Email:</span><br />
                            <span className="field-value">{data?.email}</span>
                        </p>
                    </Col>
                </Row>
                <Separator />
            </Container>
        </>
    );
};

UserDetails.propTypes = {
    data: PropTypes.shape(PROP_TYPE_USER),
};

export default UserDetails;
