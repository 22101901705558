import { Link } from "react-router-dom";
import { getFormattedDate } from "../../helpers/date_time_helper";
import { NO_DATE } from "../../common/constants";

export const COLUMNS = [
    {
        header: "Serial #",
        accessorKey: "serialNumber",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
            <Link to={`${cellProps.row.original.serialNumber}`}>
                {cellProps.row.original.serialNumber}
            </Link>
        ),
    },
    /*{
        header: 'Is Registered',
        accessorKey: 'isRegistered',
        enableColumnFilter: false,
        enableSorting: true,
    },*/
    {
        header: 'Machine Type',
        accessorKey: 'type',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: 'Name',
        accessorKey: 'machineName',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: 'Date Added',
        accessorKey: 'created',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => getFormattedDate(cellProps.row.original.created),
    },
    {
        header: 'Last Active',
        accessorKey: 'lastActive',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => cellProps.row.original.lastActive === NO_DATE ? '-' : getFormattedDate(cellProps.row.original.lastActive),
    },
    /*{
        header: 'Action',
        cell: (cellProps) => {
            return (
                <div className="d-flex gap-3">
                    <Link
                        to="#"
                        className="text-danger"
                        onClick={() => {
                            const device = cellProps.row.original;
                            onDelete(device);
                        }}>
                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                    </Link>
                </div>
            );
        }
    },*/
];
