import { useEffect, useMemo } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getAuthUserDetails } from "../../store/auth/login/actions";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAuthUserDetails,
    selectIsAuthUserDetailsLoading,
    selectIsAuthUserNotRegistered
} from "../../store/auth/login/selectors";
import { USER_ROLE } from "../../constants/user-role";

const useAuthUser = () => {
    const isAuthenticated = useIsAuthenticated();
    const authUserDetails = useSelector(selectAuthUserDetails());
    const isAuthUserDetailsLoading = useSelector(selectIsAuthUserDetailsLoading());
    const isAuthUserNotRegistered = useSelector(selectIsAuthUserNotRegistered());
    const isAdmin = useMemo(() => !!authUserDetails?.appRoles.find(({ roleName })=> roleName === USER_ROLE.ADMIN), [authUserDetails]);
    const isUser = useMemo(() => !!authUserDetails?.appRoles.find(({ roleName })=> roleName === USER_ROLE.USER) || authUserDetails?.appRoles !== undefined, [authUserDetails]);

    return {
        user: authUserDetails,
        isAdmin,
        isUser,
        isRegistered: isAuthenticated,
        isDataLoading: isAuthUserDetailsLoading,
        isAuthUserNotRegistered,
    };
};

export default useAuthUser;
