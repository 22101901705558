import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import { useMemo } from "react";
import validationSchema from "./validationSchema";

const DeviceForm = ({
    t,
    isSaving,
    onSubmit,
}) => {
    const validation = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema,
        onSubmit,
    });

    const isButtonDisabled = useMemo(() =>
        isSaving || !Object.keys(validation.touched).length || Object.keys(validation.errors).length
    , [validation]);

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}
        >
            <Row>
                <Col xs={12}>
                    <div className="mb-3">
                        <Label className="form-label">Device Serial Number</Label>
                        <Input
                            name="name"
                            type="text"
                            placeholder="Insert Device Serial Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={!!(validation.touched.name && validation.errors.name)}
                        />
                        {validation.touched.name &&
                        validation.errors.name ? (
                            <FormFeedback type="invalid">
                                {validation.errors.name}
                            </FormFeedback>
                        ) : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-end">
                        <button
                            type="submit"
                            className="btn btn-success save-user"
                            disabled={isButtonDisabled}
                        >
                            {t("Save")}
                        </button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

DeviceForm.propTypes = {
    t: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(DeviceForm);
