import { createSelector } from "reselect";
import { INIT_STATE } from "./reducer";

export const selectUserDomain = (state) => state.User || INIT_STATE;

export const selectUserList = () =>
    createSelector(selectUserDomain, ({ list }) => list);

export const selectIsUserListLoading = () =>
    createSelector(selectUserDomain, ({ isListLoading }) => isListLoading);

export const selectUserDetails = () =>
    createSelector(selectUserDomain, ({ selectedItem }) => selectedItem);

export const selectIsUserDetailsLoading = () =>
    createSelector(selectUserDomain, ({ isLoading }) => isLoading);
