import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import useDefaultPage from "../../common/hooks/defaultPage";
import { useEffect } from "react";
import {InteractionStatus} from "@azure/msal-browser";

const LandingPage = () => {
    const history = useNavigate();
    const { inProgress } = useAuth();
    const { defaultPage } = useDefaultPage();

    useEffect(() => {
        if (inProgress === InteractionStatus.None && defaultPage) {
            history(defaultPage);
        }
    }, [defaultPage, inProgress]);

    return null;
};

export default LandingPage;
