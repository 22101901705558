import listIconSvg from "../../../../assets/svg/list-icon.svg";
import PropTypes from "prop-types";

const DocLink = ({ href, title }) => {
    return (
        <a
            href={href}
            target="blank"
            className="btn btn-outline-secondary text-start text-nowrap"
            rel="noopener noreferrer"
            style={{width: '300px'}}
        >
            <img
                src={listIconSvg}
                className="me-2"
            />
            {title}
        </a>
    );
};

DocLink.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default DocLink;
