import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import useDefaultPage from "../../common/hooks/defaultPage";
import { useEffect } from "react";

const PageNotFound = () => {
    const history = useNavigate();
    const { defaultPage } = useDefaultPage();

    useEffect(() => {
        if (defaultPage) {
            history(defaultPage);
        }
    }, [defaultPage]);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    title="Page not found"
                />
                The page does not exist or you don&apos;t have permissions to see it.
            </Container>
        </div>
    );
};

export default PageNotFound;
