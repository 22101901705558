const ScreenOverlay = () => {
    return (
        <div
            className="w-100 h-100 top-0 start-0 position-absolute opacity-75 background-primary"
            style={{ backgroundColor: '#fff' }}
        />
    );
};

export default ScreenOverlay;
