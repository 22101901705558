import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_ERROR, REGISTER_USER_SUCCESS
} from "./actionTypes";

export const getAuthUserDetails = (email) => {
  return {
    type: GET_USER_DETAILS,
    payload: email,
  };
};

export const getAuthUserDetailsSuccess = ({ data }) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getAuthUserDetailsFail = () => {
  return {
    type: GET_USER_DETAILS_ERROR,
  };
};

export const registerAuthUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: user,
  };
};

export const registerAuthUserSuccess = ({ data }) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: data,
  };
};

export const registerAuthUserFail = () => {
  return {
    type: REGISTER_USER_ERROR,
  };
};
