import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import TableContainer from "../../../../components/Common/TableContainer";
import { selectDeviceList, selectIsDeviceListLoading } from "../../../../store/device/selectors";
import Spinner from "../../../../components/Common/Spinner";
import { COLUMNS } from "../../config";
import ScreenOverlay from "../../../../components/Common/ScreenOverlay";

const DeviceList = ({
    data,
    isLoading,
}) => {
    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <TableContainer
                            columns={COLUMNS}
                            data={data || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            SearchPlaceholder="Search..."
                            isCustomPageSize={true}
                            isAddButton={false}
                            buttonName="Register Machine"
                            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                            pagination="pagination"
                        />
                    </CardBody>
                </Card>
            </Col>
            {isLoading && (
                <>
                    <ScreenOverlay />
                    <Spinner />
                </>
            )}
        </Row>
    );
};

DeviceList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default compose(
    connect(createStructuredSelector({
        isLoading: selectIsDeviceListLoading(),
        data: selectDeviceList(),
    }),
    () => ({
    }),
))(DeviceList);
