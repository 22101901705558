import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import warranty from '../../../../assets/docs/warranty-statement.pdf';
import customerActivationAgreement from '../../../../assets/docs/Fecon-Customer-Activation-Agreement.pdf';
import dealerActivationAgreement from '../../../../assets/docs/Fecon-Dealer-Activation-Agreement.pdf';
import endUserLicenseAgreement from '../../../../assets/docs/Fecon-End-User-License-Agreement.pdf';
import saasTermsAndConditions from '../../../../assets/docs/SaaS-Terms-and-Conditions.pdf';
import DocLink from "../doc-link";
import HeaderBox from "../header-box";

const Warranty = ({ onNext, deviceSerialNumber }) => {
    return (
        <>
            <HeaderBox
                title="Register Machine"
                serialNumber={deviceSerialNumber}
            />

            <Card>
                <CardBody>
                    <Container fluid>
                        <Row>
                            <Col xs={12} lg={6}>
                                <h5>Fecon Inc. Limited Warranty</h5>
                                <p>NOTE: THIS IS A LIMITED WARRANTY. PLEASE READ CAREFULLY.</p>
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-end">
                                <a
                                    target="blank"
                                    href={warranty}
                                    rel='noopener noreferrer'
                                >View, download, or print the warranty statement</a>
                            </Col>
                        </Row>
                    </Container>

                    <ol>
                        <li className="p-3">Subject to the provisions set forth below, Fecon warrants that products it manufactures will be free of defects in materials and workmanship for a period of one year or 1,000 hours from the &quot;in service date&quot; for all Bull Hog mulching heads and FTX tractors. All other products are limited to six (6) months warranty as described above. THIS IS THE ONLY WARRANTY DELIVERED BY FECON AND IS IN LIEU OF ALL OTHER WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTIBILITY OR FITNESS FOR PARTICULAR PURPOSE. Such warranty is further limited as expressed below.</li>
                        <li className="p-3">In the event of any warranty claim, Fecon shall have no obligation other than to either repair or replace any item subject to warranty at its sole and absolute discretion. Place of repair or replacement shall be determined by Fecon. Freight and transportation costs to the point of replacement or repair shall be prepaid by claimant. Freight and transportation costs for return to claimant after repair or replacement of any failed items subject to warranty repair or replacement shall be paid by Fecon. Claimant shall pay freight and transportation costs for return of any items determined not to be defective or not subject to warranty and therefore not subject to repair or replacement. Items replaced shall belong to Fecon. In no event shall Fecon&apos;s financial obligation or cost under this warranty exceed the market value of the equipment involved at the time of such repair or replacement, as determined by Fecon.</li>
                        <li className="p-3">This warranty IS VOID AS AGAINST and does NOT cover effects, damage, or failures of or arising from: (a) components or parts supplied to Fecon by other manufacturers (such as hydraulic components or bearings), warranties for which will be limited to those given by the suppliers thereof to the extent transferable by Fecon; (b) ordinary wear and tear and expenditure of useful life in operation (such as tires, batteries, and other expendable components); (c) use of products manufactured by Fecon with parts, equipment, or components not authorized in advance by Fecon; (d) failure to operate the equipment in accordance with Fecon&apos;s instructions for use of equipment; (e) failure to properly service the equipment in accordance with Fecon&apos;s recommendations for same; (f) modification in a manner not specifically approved by Fecon, or the use or installation in the item of unauthorized parts or components; or (g) negligent or improper use and handling of the equipment in operation or for purposes outside its design specifications.</li>
                        <li className="p-3">IN NO EVENT SHALL FECON BE LIABLE OR RESPONSIBLE FOR ANY SPECIAL, CONSEQUENTIAL OR INDIRECT DAMAGES, INCLUDING LOST PROFITS OR LOST COMMERCIAL OPPORTUNITIES, with respect to any item covered by this limited warranty or any action or activity undertaken with the item, or for any damage sustained by any person claiming to be a third party beneficiary under this limited warranty. This limited warranty shall extend only to the initial purchaser of the Fecon product covered by this warranty.
                            This limited warranty covers only new items manufactured by Fecon. It does NOT extend to any used or rebuilt item, unless the sale order or contract for such item expressly provides for warranty coverage.</li>
                    </ol>

                    <Container fluid className="gap-3">
                        <Row>
                            <Col xs={12} lg={6} className="d-flex justify-content-center mb-3">
                                <DocLink
                                    href={customerActivationAgreement}
                                    title="Fecon Customer Activation Agreement"
                                />
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-center mb-3">
                                <DocLink
                                    href={dealerActivationAgreement}
                                    title="Fecon Dealer Activation Agreement"
                                />
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-center mb-3">
                                <DocLink
                                    href={endUserLicenseAgreement}
                                    title="Fecon End User License Agreement"
                                />
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-center mb-3">
                                <DocLink
                                    href={saasTermsAndConditions}
                                    title="Bosch SaaS Terms & Conditions"
                                />
                            </Col>
                        </Row>

                        <p className="text-center mt-5">
                            I confirm that I have read and understand all terms and conditions surrounding machine warranty registration,<br/>
                            the Activation Agreements and the End User License Agreement.
                        </p>

                        <Container className="text-center mt-3">
                            <button
                                className="btn btn-success btn-xxl"
                                onClick={() => onNext()}
                            >
                                I AGREE
                            </button>
                        </Container>
                    </Container>
                </CardBody>
            </Card>

        </>
    );
};

Warranty.propTypes = {
    deviceSerialNumber: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
};

export default Warranty;
