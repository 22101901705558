import PropTypes from "prop-types";
import { PROP_TYPE_MACHINE } from "./machine";

export const PROP_TYPE_USER = {
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    ownedDevices: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_MACHINE)).isRequired,
    operatedDevices: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_MACHINE)).isRequired,
    dealerDevices: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_MACHINE)).isRequired,
};
