import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PROP_TYPE_USER } from "../../../common/types";

const UserLink = ({ user, withArrow, isAdmin, ...otherParams }) => {
    if (isAdmin) {
        return (
            <Link
                to={`/users/${user?.id}`}
                {...otherParams}
            >{user?.displayName}{withArrow ? <span className="text-primary"> &gt;</span>: ''}</Link>
        );
    }

    return user?.displayName;
};

UserLink.propTypes = {
    user: PropTypes.shape(PROP_TYPE_USER),
    withArrow: PropTypes.bool,
    isAdmin: PropTypes.bool,
};

UserLink.defaultProps = {
    withArrow: false,
    isAdmin: false,
};

export default UserLink;
