// @flow
import { takeEvery, put, call, delay } from "redux-saga/effects";
import {
  ADD_DEVICE,
  ADD_DEVICE_AS_OWNER,
  ASSIGN_DEVICE_OWNER,
  DELETE_DEVICE,
  GET_DEVICE,
  GET_DEVICE_LIST,
  GET_DEVICE_REGISTRATION_STATUS,
  GET_MY_DEVICE_LIST,
  REGISTER_MY_DEVICE,
} from "./actionTypes";
import { toast } from "react-toastify";
import {
  assignDeviceOwnerFail,
  assignDeviceOwnerSuccess,
  deleteDeviceFail,
  deleteDeviceSuccess, fetchDevice,
  fetchDeviceList,
  getDeviceRegistrationStatusFail,
  getDeviceRegistrationStatusSuccess,
  registerMyDeviceFail,
  registerMyDeviceSuccess,
  setAddDeviceFail,
  setAddDeviceSuccess,
  setDeviceFail,
  setDeviceListFail,
  setDeviceListSuccess,
  setDeviceSuccess,
  toggleAssignOwnerFormVisibility,
  toggleDeleteDeviceConfirmation,
} from "./actions";
import {
  addDevice,
  assignDeviceOwner,
  deleteDevice,
  getDevice,
  getDeviceList,
  getDeviceListForUser,
} from "../../api/device";
import { toastError, toastSuccess } from "../../helpers/toast_helper";
import { getRegistrationStatus, submitRegistration } from "../../api/registration";

function* onFetchDeviceList() {
  try {
    const response = yield call(getDeviceList);
    yield put(setDeviceListSuccess(response));
  } catch (error) {
    yield put(setDeviceListFail(error));
    toast.error("Cannot fetch machine list", {autoClose: 2000});
  }
}

function* onFetchMyDeviceList({ payload }) {
  try {
    const response = yield call(getDeviceListForUser, payload);
    yield put(setDeviceListSuccess(response));
  } catch (error) {
    yield put(setDeviceListFail(error));
    toast.error("Cannot fetch machine list", {autoClose: 2000});
  }
}


function* onGetDevice({ payload }) {
  try {
    const response = yield call(getDevice, payload);
    yield put(setDeviceSuccess(response));
  } catch (error) {
    yield put(setDeviceFail(error));
    toast.error("Cannot get machine details", {autoClose: 2000});
  }
}

function* onAddDevice({ payload }) {
  try {
    yield call(addDevice, payload);
    yield put(setAddDeviceSuccess());
    toast.success("Machine was added successfully", {autoClose: 2000});
    yield put(fetchDeviceList(payload.userId));
  } catch (error) {
    yield put(setAddDeviceFail(error));
    toast.error("Cannot add new machine", {autoClose: 2000});
  }
}

function* onDeleteDevice({ payload }) {
  try {
    yield call(deleteDevice, payload);
    yield put(deleteDeviceSuccess());
    yield put(toggleDeleteDeviceConfirmation());
    toast.success("Machine was deleted successfully", {autoClose: 2000});
    yield put(fetchDeviceList(payload.userId));
  } catch (error) {
    yield put(toggleDeleteDeviceConfirmation());
    yield put(deleteDeviceFail(error));
    toast.error("Cannot delete machine", {autoClose: 2000});
  }
}

function* onAssignDeviceOwner({ payload }) {
  try {
    yield call(assignDeviceOwner, payload);
    yield put(assignDeviceOwnerSuccess());
    yield put(toggleAssignOwnerFormVisibility());
    toastSuccess("Machine owner was successfully updated");
    yield put(fetchDevice(payload.deviceSerialNumber));
  } catch (error) {
    yield put(toggleAssignOwnerFormVisibility());
    yield put(assignDeviceOwnerFail(error));
    toastError("Cannot update machine owner");
  }
}

function* onRegisterMyDevice({ payload }) {
  // TODO: Make some real logic when BE will be ready
  try {
    const response = yield call(submitRegistration, payload.payload);

    if (response?.success === true) {
      toastSuccess("Machine registration was finished successfully");
      yield delay(2000);
      yield put(registerMyDeviceSuccess());
      payload?.onSuccess();
    } else {
      yield put(registerMyDeviceFail(error));
      toastError("Cannot update machine registration data");
    }
  } catch (error) {
    yield put(registerMyDeviceFail(error));
    toastError("Cannot update machine registration data");
  }
}

function* onGetRegistrationStatus({ payload }) {
  try {
    const response = yield call(getRegistrationStatus, payload.deviceSerialNumber);
    yield put(getDeviceRegistrationStatusSuccess());
    payload?.onSuccess(response?.data);
  } catch (error) {
    yield put(getDeviceRegistrationStatusFail(error));
    toast.error("Cannot fetch machine registration status", {autoClose: 2000});
  }
}

export function* DeviceSaga() {
  yield takeEvery(GET_DEVICE_LIST, onFetchDeviceList);
  yield takeEvery(GET_MY_DEVICE_LIST, onFetchMyDeviceList);

  yield takeEvery(GET_DEVICE, onGetDevice);
  yield takeEvery(ADD_DEVICE, onAddDevice);
  yield takeEvery(ADD_DEVICE_AS_OWNER, onAssignDeviceOwner);
  yield takeEvery(DELETE_DEVICE, onDeleteDevice);

  yield takeEvery(ASSIGN_DEVICE_OWNER, onAssignDeviceOwner);
  yield takeEvery(REGISTER_MY_DEVICE, onRegisterMyDevice);

  yield takeEvery(GET_DEVICE_REGISTRATION_STATUS, onGetRegistrationStatus);
}

export default DeviceSaga;
