import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../../../../components/Common/Modal";
import DeviceForm from "./form";
import { addDevice, toggleShowDeviceForm } from "../../../../store/device/actions";
import { selectIsDeviceFormSaving, selectIsDeviceFormVisible } from "../../../../store/device/selectors";

const DeviceFormModal = ({
    t,
    isFormVisible,
    isFormSaving,
    addDeviceDispatch,
    toggleShowDeviceFormDispatch,
}) => {
    const onSaveDevice = (values) => {
        addDeviceDispatch({
            userId: 1,
            deviceSerialNumber: values.name,
        });
        toggleShowDeviceFormDispatch();
    };

    return (
        <Modal
            isOpen={isFormVisible}
            onToggle={toggleShowDeviceFormDispatch}
            title={t("Add Machine")}
        >
            <DeviceForm
                onSubmit={onSaveDevice}
                isSaving={isFormSaving}
            />
        </Modal>
    );
};

DeviceFormModal.propTypes = {
    t: PropTypes.func,
    isFormVisible: PropTypes.bool,
    isFormSaving: PropTypes.bool,
    toggleShowDeviceFormDispatch: PropTypes.func,
    addDeviceDispatch: PropTypes.func,
};

export default withTranslation()(compose(
    connect(createStructuredSelector({
        isFormVisible: selectIsDeviceFormVisible(),
        isFormSaving: selectIsDeviceFormSaving(),
    }),
    (dispatch) => ({
        toggleShowDeviceFormDispatch: bindActionCreators(toggleShowDeviceForm, dispatch),
        addDeviceDispatch: bindActionCreators(addDevice, dispatch),
    }),
))(DeviceFormModal));
