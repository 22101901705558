import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
} from "./actionTypes";

export const INIT_STATE = {
  authUser: null,
  isLoading: false,
  isNotRegisteredUser: false,
  isRegistrationInProgress: false,
  isRegistrationFailed: false,
};

const login = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_DETAILS:
      state = {
        ...state,
        isLoading: true,
        isNotRegisteredUser: false,
      };
      break;
    case GET_USER_DETAILS_SUCCESS:
      state = {
        ...state,
        authUser: action.payload,
        isLoading: false,
      };
      break;
    case GET_USER_DETAILS_ERROR:
      state = {
        ...state,
        isLoading: false,
        isNotRegisteredUser: true,
      };
      break;
    case REGISTER_USER:
      state = {
        ...state,
        isRegistrationInProgress: true,
        isRegistrationFailed: false,
      };
      break;
    case REGISTER_USER_SUCCESS:
      state = {
        ...state,
        isRegistrationInProgress: false,
      };
      break;
    case REGISTER_USER_ERROR:
      state = {
        ...state,
        isRegistrationInProgress: false,
        isRegistrationFailed: true
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
