import { ToastContainer as ReactToastifyContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => {
    return (
        <ReactToastifyContainer />
    );
};

export default ToastContainer;
