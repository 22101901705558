import * as Yup from "yup";

export default Yup.object({
    serialNumber: Yup.string().required("Please enter Machine Serial Number"),
    deliveryDate: Yup.string().required("Please enter Delivery Date"),
    isNew: Yup.string().required("Please enter Machine Purchase Status"),
    hasShippingOrTransportDamage: Yup.string().required("Please select the option"),
    hasManual: Yup.string().required("Please select the option"),
    allOptionsInstalled: Yup.string().required("Please select the option"),
    dealerReviewMaintenanceProcedures: Yup.string().required("Please select the option"),
    dealerReviewSafetyProcedures: Yup.string().required("Please select the option"),
});
