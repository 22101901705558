// @flow
import { takeEvery, put, call } from "redux-saga/effects";
import { GET_DASHBOARD_DETAILS } from "./actionTypes";
import { toast } from "react-toastify";
import {
  setDashboardDetailsFail,
  setDashboardDetailsSuccess,
} from "./actions";
import { getDashboardDetails } from "../../api/dashboard";

function* onFetchDashboardDetails({ payload: userId }) {
  try {
    const response = yield call(getDashboardDetails, userId);
    yield put(setDashboardDetailsSuccess(response));
  } catch (error) {
    yield put(setDashboardDetailsFail());
    toast.error("Cannot fetch dashboard details", {autoClose: 2000});
  }
}

export function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DETAILS, onFetchDashboardDetails);
}

export default DashboardSaga;
