import React from "react";
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";

const Breadcrumb = ({ title, children }) => {
  return (
    <Row>
      <Col xs={12} className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{title}</h4>
          {children}
          {/*<div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem><Link to="#">{props.title}</Link></BreadcrumbItem>
              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>*/}
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

Breadcrumb.defautProps = {
    children: null,
};

export default Breadcrumb;
