import {
  GET_DEVICE_LIST,
  GET_MY_DEVICE_LIST,
  GET_DEVICE,
  SET_DEVICE_LIST_SUCCESS,
  SET_DEVICE_LIST_FAIL,
  TOGGLE_SHOW_DEVICE_FORM,
  ADD_DEVICE,
  ADD_DEVICE_FAIL,
  ADD_DEVICE_SUCCESS,
  TOGGLE_DELETE_DEVICE_CONFIRMATION,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  SET_DEVICE_SUCCESS,
  SET_DEVICE_FAIL,
  ASSIGN_DEVICE_OWNER,
  TOGGLE_SHOW_ASSIGN_OWNER_FORM,
  ASSIGN_DEVICE_OWNER_SUCCESS,
  ASSIGN_DEVICE_OWNER_FAIL,
  ADD_DEVICE_AS_OWNER,
  ADD_DEVICE_AS_OWNER_SUCCESS,
  ADD_DEVICE_AS_OWNER_FAIL,
  REGISTER_MY_DEVICE,
  REGISTER_MY_DEVICE_SUCCESS,
  REGISTER_MY_DEVICE_FAIL,
  GET_DEVICE_REGISTRATION_STATUS,
  GET_DEVICE_REGISTRATION_STATUS_SUCCESS,
  GET_DEVICE_REGISTRATION_STATUS_FAIL,
} from "./actionTypes";

// Device list
export const fetchDeviceList = () => ({
  type: GET_DEVICE_LIST,
});

export const fetchMyDeviceList = (userId) => ({
  type: GET_MY_DEVICE_LIST,
  payload: userId,
});

export const setDeviceListSuccess = data => ({
  type: SET_DEVICE_LIST_SUCCESS,
  payload: data,
});

export const setDeviceListFail = data => ({
  type: SET_DEVICE_LIST_FAIL,
  payload: data,
});

// Device
export const fetchDevice = deviceSerialNumber => ({
  type: GET_DEVICE,
  payload: deviceSerialNumber,
});

export const setDeviceSuccess = (data) => ({
  type: SET_DEVICE_SUCCESS,
  payload: data,
});

export const setDeviceFail = (data) => ({
  type: SET_DEVICE_FAIL,
  payload: data,
});

// TODO: DEPRECATED
export const addDevice = ({ userId, deviceSerialNumber }) => ({
  type: ADD_DEVICE,
  payload: {
    userId,
    deviceSerialNumber
  },
});

export const setAddDeviceSuccess = (data) => ({
  type: ADD_DEVICE_SUCCESS,
  payload: data,
});

export const setAddDeviceFail = (data) => ({
  type: ADD_DEVICE_FAIL,
  payload: data,
});

export const addDeviceAsOwner = ({ userId, deviceSerialNumber }) => ({
  type: ADD_DEVICE_AS_OWNER,
  payload: {
    userId,
    deviceSerialNumber
  },
});

export const setAddDeviceAsOwnerSuccess = (data) => ({
  type: ADD_DEVICE_AS_OWNER_SUCCESS,
  payload: data,
});

export const setAddDeviceAsOwnerFail = (data) => ({
  type: ADD_DEVICE_AS_OWNER_FAIL,
  payload: data,
});

export const toggleShowDeviceForm = () => ({
  type: TOGGLE_SHOW_DEVICE_FORM,
});

export const toggleDeleteDeviceConfirmation = (data) => ({
  type: TOGGLE_DELETE_DEVICE_CONFIRMATION,
  payload: data
});

export const deleteDevice = ({ userId, deviceSerialNumber }) => ({
  type: DELETE_DEVICE,
  payload: {
    userId,
    deviceSerialNumber,
  }
});

export const deleteDeviceSuccess = () => ({
  type: DELETE_DEVICE_SUCCESS,
});

export const deleteDeviceFail = () => ({
  type: DELETE_DEVICE_FAIL,
});

export const toggleAssignOwnerFormVisibility = () => ({
  type: TOGGLE_SHOW_ASSIGN_OWNER_FORM,
});

export const assignDeviceOwner = ({ userId, deviceSerialNumber }) => ({
  type: ASSIGN_DEVICE_OWNER,
  payload: {
    userId,
    deviceSerialNumber,
  },
});

export const assignDeviceOwnerSuccess = () => ({
  type: ASSIGN_DEVICE_OWNER_SUCCESS,
});

export const assignDeviceOwnerFail = () => ({
  type: ASSIGN_DEVICE_OWNER_FAIL,
});

export const registerMyDevice = ({ payload, onSuccess }) => ({
  type: REGISTER_MY_DEVICE,
  payload: {
    payload,
    onSuccess,
  },
});

export const registerMyDeviceSuccess = () => ({
  type: REGISTER_MY_DEVICE_SUCCESS,
});

export const registerMyDeviceFail = () => ({
  type: REGISTER_MY_DEVICE_FAIL,
});

// Get device registration status
export const getDeviceRegistrationStatus = ({ deviceSerialNumber, onSuccess }) => ({
  type: GET_DEVICE_REGISTRATION_STATUS,
  payload: {
    deviceSerialNumber,
    onSuccess,
  },
});

export const getDeviceRegistrationStatusSuccess = () => ({
  type: GET_DEVICE_REGISTRATION_STATUS_SUCCESS,
});

export const getDeviceRegistrationStatusFail = () => ({
  type: GET_DEVICE_REGISTRATION_STATUS_FAIL,
});
