import { Card, CardText, CardBody, CardTitle, Container } from "reactstrap";
import './styles.scss';
import PropTypes from "prop-types";
import Spinner from "../../../../components/Common/Spinner";
import ScreenOverlay from "../../../../components/Common/ScreenOverlay";
import { Link } from "react-router-dom";

const DashboardWidget = ({
    title,
    totalCount,
    totalCountDescr,
    recentCount,
    recentCountDescr,
    isLoading,
    buttonName,
    buttonLink,
}) => {
    return (
        <Card className="dashboard-widget">
            <CardBody>
                <CardTitle tag="h2" className="title text-black">{title}</CardTitle>
                <Container className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className="total-count">{isLoading ? '?' : totalCount}</div>
                    <CardText className="total-count-descr">{totalCountDescr}</CardText>
                    <CardText className="recent-count-descr">
                        <span className="recent-count">{isLoading ? '?' : recentCount}</span> {recentCountDescr}
                    </CardText>
                    <Link
                        className="details-button btn btn-outline-secondary w-100"
                        to={buttonLink}
                    >{buttonName}</Link>
                </Container>
                {isLoading && (
                    <>
                        <ScreenOverlay />
                        <Spinner />
                    </>
                )}
            </CardBody>
        </Card>
    );
};

DashboardWidget.propTypes = {
    title: PropTypes.string.isRequired,
    totalCount: PropTypes.number,
    totalCountDescr: PropTypes.string.isRequired,
    recentCount: PropTypes.number,
    recentCountDescr: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    buttonName: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
};

DashboardWidget.defaultProps = {
    totalCount: 0,
    recentCount: 0,
    isLoading: false,
};

export default DashboardWidget;
