export const NO_DATE = '0001-01-01T00:00:00';

export const STATES_AND_COUNTRIES = [
    {
        "Id": "1",
        "StateCode": "AK",
        "CountryId": "1",
        "State": "Alaska",
        "Country": "United States"
    },
    {
        "Id": "2",
        "StateCode": "AL",
        "CountryId": "1",
        "State": "Alabama",
        "Country": "United States"
    },
    {
        "Id": "3",
        "StateCode": "AR",
        "CountryId": "1",
        "State": "Arkansas",
        "Country": "United States"
    },
    {
        "Id": "4",
        "StateCode": "AZ",
        "CountryId": "1",
        "State": "Arizona",
        "Country": "United States"
    },
    {
        "Id": "5",
        "StateCode": "CA",
        "CountryId": "1",
        "State": "California",
        "Country": "United States"
    },
    {
        "Id": "6",
        "StateCode": "CO",
        "CountryId": "1",
        "State": "Colorado",
        "Country": "United States"
    },
    {
        "Id": "7",
        "StateCode": "CT",
        "CountryId": "1",
        "State": "Connecticut",
        "Country": "United States"
    },
    {
        "Id": "8",
        "StateCode": "DC",
        "CountryId": "1",
        "State": "District of Columbia",
        "Country": "United States"
    },
    {
        "Id": "9",
        "StateCode": "DE",
        "CountryId": "1",
        "State": "Delaware",
        "Country": "United States"
    },
    {
        "Id": "10",
        "StateCode": "FL",
        "CountryId": "1",
        "State": "Florida",
        "Country": "United States"
    },
    {
        "Id": "11",
        "StateCode": "GA",
        "CountryId": "1",
        "State": "Georgia",
        "Country": "United States"
    },
    {
        "Id": "12",
        "StateCode": "HI",
        "CountryId": "1",
        "State": "Hawaii",
        "Country": "United States"
    },
    {
        "Id": "13",
        "StateCode": "IA",
        "CountryId": "1",
        "State": "Iowa",
        "Country": "United States"
    },
    {
        "Id": "14",
        "StateCode": "ID",
        "CountryId": "1",
        "State": "Idaho",
        "Country": "United States"
    },
    {
        "Id": "15",
        "StateCode": "IL",
        "CountryId": "1",
        "State": "Illinois",
        "Country": "United States"
    },
    {
        "Id": "16",
        "StateCode": "IN",
        "CountryId": "1",
        "State": "Indiana",
        "Country": "United States"
    },
    {
        "Id": "17",
        "StateCode": "KS",
        "CountryId": "1",
        "State": "Kansas",
        "Country": "United States"
    },
    {
        "Id": "18",
        "StateCode": "KY",
        "CountryId": "1",
        "State": "Kentucky",
        "Country": "United States"
    },
    {
        "Id": "19",
        "StateCode": "LA",
        "CountryId": "1",
        "State": "Louisiana",
        "Country": "United States"
    },
    {
        "Id": "20",
        "StateCode": "MA",
        "CountryId": "1",
        "State": "Massachusetts",
        "Country": "United States"
    },
    {
        "Id": "21",
        "StateCode": "MD",
        "CountryId": "1",
        "State": "Maryland",
        "Country": "United States"
    },
    {
        "Id": "22",
        "StateCode": "ME",
        "CountryId": "1",
        "State": "Maine",
        "Country": "United States"
    },
    {
        "Id": "23",
        "StateCode": "MI",
        "CountryId": "1",
        "State": "Michigan",
        "Country": "United States"
    },
    {
        "Id": "24",
        "StateCode": "MN",
        "CountryId": "1",
        "State": "Minnesota",
        "Country": "United States"
    },
    {
        "Id": "25",
        "StateCode": "MO",
        "CountryId": "1",
        "State": "Missouri",
        "Country": "United States"
    },
    {
        "Id": "26",
        "StateCode": "MS",
        "CountryId": "1",
        "State": "Mississippi",
        "Country": "United States"
    },
    {
        "Id": "27",
        "StateCode": "MT",
        "CountryId": "1",
        "State": "Montana",
        "Country": "United States"
    },
    {
        "Id": "28",
        "StateCode": "NC",
        "CountryId": "1",
        "State": "North Carolina",
        "Country": "United States"
    },
    {
        "Id": "29",
        "StateCode": "ND",
        "CountryId": "1",
        "State": "North Dakota",
        "Country": "United States"
    },
    {
        "Id": "30",
        "StateCode": "NE",
        "CountryId": "1",
        "State": "Nebraska",
        "Country": "United States"
    },
    {
        "Id": "31",
        "StateCode": "NH",
        "CountryId": "1",
        "State": "New Hampshire",
        "Country": "United States"
    },
    {
        "Id": "32",
        "StateCode": "NJ",
        "CountryId": "1",
        "State": "New Jersey",
        "Country": "United States"
    },
    {
        "Id": "33",
        "StateCode": "NM",
        "CountryId": "1",
        "State": "New Mexico",
        "Country": "United States"
    },
    {
        "Id": "34",
        "StateCode": "NV",
        "CountryId": "1",
        "State": "Nevada",
        "Country": "United States"
    },
    {
        "Id": "35",
        "StateCode": "NY",
        "CountryId": "1",
        "State": "New York",
        "Country": "United States"
    },
    {
        "Id": "36",
        "StateCode": "OH",
        "CountryId": "1",
        "State": "Ohio",
        "Country": "United States"
    },
    {
        "Id": "37",
        "StateCode": "OK",
        "CountryId": "1",
        "State": "Oklahoma",
        "Country": "United States"
    },
    {
        "Id": "38",
        "StateCode": "OR",
        "CountryId": "1",
        "State": "Oregon",
        "Country": "United States"
    },
    {
        "Id": "39",
        "StateCode": "PA",
        "CountryId": "1",
        "State": "Pennsylvania",
        "Country": "United States"
    },
    {
        "Id": "40",
        "StateCode": "RI",
        "CountryId": "1",
        "State": "Rhode Island",
        "Country": "United States"
    },
    {
        "Id": "41",
        "StateCode": "SC",
        "CountryId": "1",
        "State": "South Carolina",
        "Country": "United States"
    },
    {
        "Id": "42",
        "StateCode": "SD",
        "CountryId": "1",
        "State": "South Dakota",
        "Country": "United States"
    },
    {
        "Id": "43",
        "StateCode": "TN",
        "CountryId": "1",
        "State": "Tennessee",
        "Country": "United States"
    },
    {
        "Id": "44",
        "StateCode": "TX",
        "CountryId": "1",
        "State": "Texas",
        "Country": "United States"
    },
    {
        "Id": "45",
        "StateCode": "UT",
        "CountryId": "1",
        "State": "Utah",
        "Country": "United States"
    },
    {
        "Id": "46",
        "StateCode": "VA",
        "CountryId": "1",
        "State": "Virginia",
        "Country": "United States"
    },
    {
        "Id": "47",
        "StateCode": "VT",
        "CountryId": "1",
        "State": "Vermont",
        "Country": "United States"
    },
    {
        "Id": "48",
        "StateCode": "WA",
        "CountryId": "1",
        "State": "Washington",
        "Country": "United States"
    },
    {
        "Id": "49",
        "StateCode": "WI",
        "CountryId": "1",
        "State": "Wisconsin",
        "Country": "United States"
    },
    {
        "Id": "50",
        "StateCode": "WV",
        "CountryId": "1",
        "State": "West Virginia",
        "Country": "United States"
    },
    {
        "Id": "51",
        "StateCode": "WY",
        "CountryId": "1",
        "State": "Wyoming",
        "Country": "United States"
    },
    {
        "Id": "52",
        "StateCode": "PR",
        "CountryId": "1",
        "State": "Puerto Rico",
        "Country": "United States"
    },
    {
        "Id": "53",
        "StateCode": "VI",
        "CountryId": "1",
        "State": "Virgin Islands",
        "Country": "United States"
    },
    {
        "Id": "55",
        "StateCode": "NL",
        "CountryId": "39",
        "State": "Newfoundland and Labrador",
        "Country": "Canada"
    },
    {
        "Id": "56",
        "StateCode": "PE",
        "CountryId": "39",
        "State": "Prince Edward Island",
        "Country": "Canada"
    },
    {
        "Id": "57",
        "StateCode": "NS",
        "CountryId": "39",
        "State": "Nova Scotia",
        "Country": "Canada"
    },
    {
        "Id": "58",
        "StateCode": "NB",
        "CountryId": "39",
        "State": "New Brunswick",
        "Country": "Canada"
    },
    {
        "Id": "59",
        "StateCode": "QC",
        "CountryId": "39",
        "State": "Quebec",
        "Country": "Canada"
    },
    {
        "Id": "60",
        "StateCode": "ON",
        "CountryId": "39",
        "State": "Ontario",
        "Country": "Canada"
    },
    {
        "Id": "61",
        "StateCode": "MB",
        "CountryId": "39",
        "State": "Manitoba",
        "Country": "Canada"
    },
    {
        "Id": "62",
        "StateCode": "SK",
        "CountryId": "39",
        "State": "Saskatchewan",
        "Country": "Canada"
    },
    {
        "Id": "63",
        "StateCode": "AB",
        "CountryId": "39",
        "State": "Alberta",
        "Country": "Canada"
    },
    {
        "Id": "64",
        "StateCode": "BC",
        "CountryId": "39",
        "State": "British Columbia",
        "Country": "Canada"
    },
    {
        "Id": "65",
        "StateCode": "YK",
        "CountryId": "39",
        "State": "Yukon",
        "Country": "Canada"
    },
    {
        "Id": "66",
        "StateCode": "NT",
        "CountryId": "39",
        "State": "Northwest Territories",
        "Country": "Canada"
    },
    {
        "Id": "67",
        "StateCode": "NU",
        "CountryId": "39",
        "State": "Nunavut",
        "Country": "Canada"
    }
];
