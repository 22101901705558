import { useEffect } from "react";
import PropTypes from "prop-types";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import {InteractionStatus} from "@azure/msal-browser";

const Logout = () => {
  const history = useNavigate();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isAuthenticated) {
        instance.logoutRedirect().then(() => {
          history('/');
        });
      } else {
        history('/');
      }
    }
  }, [instance, inProgress, isAuthenticated]);

  return null;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
