import PropTypes from "prop-types";
import { InteractionStatus } from "@azure/msal-browser";
import withRouter from "../components/Common/withRouter";
import useAuth from "../pages/Landing/hooks/useAuth";
import { useEffect } from "react";
import {useSearchParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";

const AuthMiddleware = (props) => {
  const { inProgress, isAuthenticated, onAuthorize, onAuthorizeByCode } = useAuth();
  const { instance } = useMsal();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('auth_code');
  const codeVerifier = searchParams.get('auth_code_verifier');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    const customState = state ? JSON.parse(decodeURIComponent(state)) : {};

    if (inProgress === InteractionStatus.None) {
      if (code && codeVerifier) {
        instance.logoutRedirect({ state: JSON.stringify({code, codeVerifier}) });
      } else if (customState.code && customState.codeVerifier) {
        onAuthorizeByCode(customState.code, customState.codeVerifier);
      } else if (!isAuthenticated) {
        onAuthorize();
      }
    }

  }, [inProgress, isAuthenticated]);

  return inProgress === InteractionStatus.None && isAuthenticated ? props.children : null;
};

AuthMiddleware.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(AuthMiddleware);
