import { Link } from "react-router-dom";
import { getFormattedDate } from "../../helpers/date_time_helper";

export const GET_COLUMNS = [
    {
        header: "Name",
        accessorKey: "displayName",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
            <Link to={`${cellProps.row.original.id}`}>
                {cellProps.row.original.displayName}
            </Link>
        ),
    },
    {
        header: 'User ID',
        accessorKey: 'id',
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'Email',
        accessorKey: 'email',
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'Date Added',
        accessorKey: 'created',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => getFormattedDate(cellProps.row.original.created),
    },
];
