import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const useAuth = () => {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress, instance } = useMsal();

    const onAuthorize = () => {
       instance.loginRedirect();
    };

    const onAuthorizeByCode = (code, codeVerifier) => {
        try {
            instance.acquireTokenByCode({
                code,
                codeVerifier,
                scopes: ["openid", "profile", "offline_access"],
            }).catch(() => {
                onAuthorize();
            });
        } catch (e) {
            onAuthorize();
        }
    };

    return {
        inProgress,
        isAuthenticated,
        onAuthorize,
        onAuthorizeByCode,
    };
};

export default useAuth;
