import { API_URL } from "../config";

export const getUserList = async () => {
    const response = await fetch(`${API_URL}/User/GetAllUsers`);
    return response.json();
};

export const getUser = async (userId) => {
    const response = await fetch(`${API_URL}/User/GetUser?userId=${encodeURIComponent(userId)}`);
    return response.json();
};

export const getUserByEmail = async (email) => {
    const response = await fetch(`${API_URL}/User/GetUserByEmail?email=${encodeURIComponent(email)}`);
    return response.json();
};

export const createUser = async (user) => {
    const response = await fetch(`${API_URL}/User/CreateUser`,{
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
    });
    return response.json();
};
