import PropTypes from "prop-types";
import { Modal as ReactstrapModal, ModalBody, ModalHeader } from "reactstrap";

const Modal = ({ title, isOpen, onToggle, children }) => {
    return (
        <ReactstrapModal isOpen={isOpen} toggle={onToggle}>
            <ModalHeader toggle={onToggle} tag="h4">
                {title}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </ReactstrapModal>
    );
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default Modal;
