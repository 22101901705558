import PropTypes from "prop-types";
import { Container } from "reactstrap";
import BackLink from "../../../../components/Common/BackLink";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const HeaderBox = ({ onPrev, title, serialNumber, isDisabled }) => {
    return (
        <>
            <BackLink onClick={onPrev} disabled={isDisabled}/>

            <Container fluid className="mt-4 d-flex justify-content-between">
                <Breadcrumbs title={title} />
                Serial number: {serialNumber}
            </Container>
        </>
    );
};

HeaderBox.propTypes = {
    title: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    onPrev: PropTypes.func,
};

HeaderBox.defaultProps = {
    onPrev: undefined,
};

export default HeaderBox;
