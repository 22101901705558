import { createSelector } from "reselect";
import { INIT_STATE } from "./reducer";

export const selectDashboardDomain = (state) => state.Dashboard || INIT_STATE;

export const selectDashboardDetails = () =>
    createSelector(selectDashboardDomain, ({ data }) => data);

export const selectDashboardIsLoading = () =>
    createSelector(selectDashboardDomain, ({ isLoading }) => isLoading);
