import {
  GET_USER_LIST,
  GET_USER,
  SET_USER_LIST_SUCCESS,
  SET_USER_LIST_FAIL,
  SET_USER_SUCCESS,
  SET_USER_FAIL,
} from "./actionTypes";

// User list
export const fetchUserList = () => ({
  type: GET_USER_LIST,
});

export const setUserListSuccess = data => ({
  type: SET_USER_LIST_SUCCESS,
  payload: data,
});

export const setUserListFail = data => ({
  type: SET_USER_LIST_FAIL,
  payload: data,
});

// User
export const fetchUser = userId => ({
  type: GET_USER,
  payload: userId,
});

export const setUserSuccess = data => ({
  type: SET_USER_SUCCESS,
  payload: data,
});

export const setUserFail = data => ({
  type: SET_USER_FAIL,
  payload: data,
});

