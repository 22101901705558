// @flow
import {
  GET_DEVICE_LIST,
  GET_DEVICE,
  SET_DEVICE_LIST_SUCCESS,
  SET_DEVICE_LIST_FAIL,
  TOGGLE_SHOW_DEVICE_FORM,
  ADD_DEVICE,
  ADD_DEVICE_FAIL,
  ADD_DEVICE_SUCCESS,
  TOGGLE_DELETE_DEVICE_CONFIRMATION,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  SET_DEVICE_SUCCESS,
  SET_DEVICE_FAIL,
  GET_MY_DEVICE_LIST,
  TOGGLE_SHOW_ASSIGN_OWNER_FORM,
  ASSIGN_DEVICE_OWNER,
  ASSIGN_DEVICE_OWNER_SUCCESS,
  ASSIGN_DEVICE_OWNER_FAIL,
  GET_DEVICE_REGISTRATION_STATUS,
  GET_DEVICE_REGISTRATION_STATUS_SUCCESS,
  GET_DEVICE_REGISTRATION_STATUS_FAIL,
  REGISTER_MY_DEVICE,
  REGISTER_MY_DEVICE_SUCCESS,
  REGISTER_MY_DEVICE_FAIL,
} from "./actionTypes";

export const INIT_STATE = {
  list: [],
  isListLoading: false,
  selectedItem: null,
  isFormVisible: false,
  isFormSaving: false,
  isItemDeleting: false,
  isDeleteConfirmationModalVisible: false,
  isLoading: false,
  isAssignOwnerFormVisible: false,
  isAssignOwnerFormSaving: false,
  isRegistrationStatusLoading: false,
  isRegistrationLoading: false,
};

const Device = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_DEVICE_LIST:
    case GET_MY_DEVICE_LIST:
      return {
        ...state,
        list: [],
        isListLoading: true,
      };
    case SET_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload?.data,
        isListLoading: false,
      };
    case SET_DEVICE_LIST_FAIL:
      return {
        ...state,
        list: [],
        isListLoading: false,
      };
    case GET_DEVICE:
      return {
        ...state,
        selectedItem: null,
        isLoading: true,
      };
    case SET_DEVICE_SUCCESS:
      return {
        ...state,
        selectedItem: action.payload?.data,
        isLoading: false,
      };

    case SET_DEVICE_FAIL:
      return {
        ...state,
        selectedItem: null,
        isLoading: false,
      };
    case ADD_DEVICE:
      return {
        ...state,
        isFormSaving: true,
      };
    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        isFormSaving: false,
      };
    case ADD_DEVICE_FAIL:
      return {
        ...state,
        isFormSaving: false,
      };
    case TOGGLE_DELETE_DEVICE_CONFIRMATION:
      return {
        ...state,
        isDeleteConfirmationModalVisible: !state.isDeleteConfirmationModalVisible,
        selectedItem: state.isDeleteConfirmationModalVisible ? null : action.payload,
      };
    case DELETE_DEVICE:
      return {
        ...state,
        isItemDeleting: true,
      };
    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        isItemDeleting: false,
      };
    case DELETE_DEVICE_FAIL:
      return {
        ...state,
        isItemDeleting: false,
      };
    case TOGGLE_SHOW_DEVICE_FORM:
      return {
        ...state,
        isFormVisible: !state.isFormVisible,
      };
    case TOGGLE_SHOW_ASSIGN_OWNER_FORM:
      return {
        ...state,
        isAssignOwnerFormVisible: !state.isAssignOwnerFormVisible,
      };
    case ASSIGN_DEVICE_OWNER:
      return {
        ...state,
        isAssignOwnerFormSaving: true,
      };
    case ASSIGN_DEVICE_OWNER_SUCCESS:
    case ASSIGN_DEVICE_OWNER_FAIL:
      return {
        ...state,
        isAssignOwnerFormSaving: false,
      };

    case GET_DEVICE_REGISTRATION_STATUS:
      return {
        ...state,
        isRegistrationStatusLoading: true,
      };
    case GET_DEVICE_REGISTRATION_STATUS_SUCCESS:
    case GET_DEVICE_REGISTRATION_STATUS_FAIL:
      return {
        ...state,
        isRegistrationStatusLoading: INIT_STATE.isRegistrationStatusLoading,
      };

    case REGISTER_MY_DEVICE:
      return {
        ...state,
        isRegistrationLoading: true,
      };
    case REGISTER_MY_DEVICE_SUCCESS:
    case REGISTER_MY_DEVICE_FAIL:
      return {
        ...state,
        isRegistrationLoading: INIT_STATE.isRegistrationLoading,
      };

    default:
      return state;
  }
};

export default Device;
