// @flow
import {
  GET_DASHBOARD_DETAILS, SET_DASHBOARD_DETAILS_FAIL, SET_DASHBOARD_DETAILS_SUCCESS
} from "./actionTypes";

export const INIT_STATE = {
  data: null,
  isLoading: false,
};

const Dashboard = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_DASHBOARD_DETAILS:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case SET_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case SET_DASHBOARD_DETAILS_FAIL:
      return {
        ...state,
        data: null,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default Dashboard;
