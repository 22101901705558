import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { GET_USER_DETAILS, REGISTER_USER } from "./actionTypes";
import { createUser, getUserByEmail } from "../../../api/user";
import {
  getAuthUserDetails,
  getAuthUserDetailsFail,
  getAuthUserDetailsSuccess,
  registerAuthUserFail,
  registerAuthUserSuccess
} from "./actions";
import {toastError, toastSuccess} from "../../../helpers/toast_helper";

function* getAuthUserDetailsWorker({ payload }) {
  try {
    const response = yield call(getUserByEmail, payload);

    if (response?.success) {
      yield put(getAuthUserDetailsSuccess(response));
    } else {
      yield put(getAuthUserDetailsFail(error));
    }
  } catch (error) {
    yield put(getAuthUserDetailsFail(error));
    //toastError("Cannot fetch authorized user details");
  }
}

function* registerAuthUserWorker({ payload }) {
  try {
    const response = yield call(createUser, payload);

    if (response?.success) {
      yield put(registerAuthUserSuccess(response));
      toastSuccess("User was successfully registered");

      yield put(getAuthUserDetails(payload.email));
    } else {
      yield put(registerAuthUserFail(error));
    }
  } catch (error) {
    yield put(registerAuthUserFail(error));
    toastError("Cannot register user");
  }
}

function* authSaga() {
  yield takeEvery(GET_USER_DETAILS, getAuthUserDetailsWorker);
  yield takeEvery(REGISTER_USER, registerAuthUserWorker);
}

export default authSaga;
