import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import useAuthUser from "./authUser";

const useDefaultPage = () => {
    const isAuthenticated = useIsAuthenticated();
    const { isRegistered, isAdmin, isUser, isDataLoading, isAuthUserNotRegistered } = useAuthUser();

    const [defaultPage, setDefaultPage] = useState();

    useEffect(() => {
        if (!isDataLoading && isRegistered) {
            if (isAdmin) {
                setDefaultPage('/dashboard');
            } else if (isUser) {
                setDefaultPage('/machines');
            }
        }
    }, [isAuthenticated, isRegistered, isAuthUserNotRegistered, isAdmin, isUser]);

    return { defaultPage };
};

export default useDefaultPage;
