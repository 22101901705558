import PropTypes from "prop-types";
import HeaderBox from "../header-box";
import { Card, CardBody, Col, Container, Row, Form } from "reactstrap";
import Input from "../../../../components/Common/Input";
import {useFormik} from "formik";
import validationSchema from "./validationSchema";
import { useMemo } from "react";
import InputRadio from "../../../../components/Common/InputRadio";
import Select from "../../../../components/Common/Select";
import {BUSINESS_TYPES_OPTIONS} from "../user-information-form/constants";

const getDefaultBooleanValue = (value) => typeof value === 'boolean' ? value : "";

const DeviceInformationForm = ({ data, onPrev, onNext, deviceSerialNumber, isRegistrationLoading }) => {
    const form = useFormik({
        initialValues: {
            serialNumber: deviceSerialNumber,
            deliveryDate: data?.deliveryDate || "",
            hourMeter: data?.hourMeter || "",
            isNew: data?.isNew || "",
            hasShippingOrTransportDamage: getDefaultBooleanValue(data?.hasShippingOrTransportDamage),
            isToolKitComplete: getDefaultBooleanValue(data?.isToolKitComplete),
            hasManual: getDefaultBooleanValue(data?.hasManual),
            allOptionsInstalled: getDefaultBooleanValue(data?.allOptionsInstalled),
            dealerReviewMaintenanceProcedures: getDefaultBooleanValue(data?.dealerReviewMaintenanceProcedures),
            dealerReviewSafetyProcedures: getDefaultBooleanValue(data?.dealerReviewSafetyProcedures),
        },
        validationSchema,
        validateOnMount: true,
    });

    const isButtonDisabled = useMemo(() => Object.keys(form.errors).length, [form]);
    const newOrUsedOptions = useMemo(() => [{ label: "New", value: 'new' }, { label: "Used", value: 'used' }], []);

    return (
        <>
            <HeaderBox
                title="Register Machine"
                serialNumber={deviceSerialNumber}
                onPrev={() => onPrev({ device: form.values })}
                isDisabled={isRegistrationLoading}
            />

            <Card>
                <CardBody>
                    <Container fluid>
                        <Form
                            onSubmit={e => {
                                e.preventDefault();
                                onNext({
                                    device: form.values,
                                });
                                return false;
                            }}
                        >
                            <Row className="mb-4">
                                <Col xs={12} className="font-size-18 fw-bold pb-3 text-uppercase">
                                    Machine Information
                                </Col>
                                <Col xs={12} xl={4}>
                                    <div className="mb-3">
                                        <Input
                                            label="Machine Serial Number"
                                            name="serialNumber"
                                            placeholder="Machine Serial Number"
                                            form={form}
                                            value={form.values.serialNumber}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <div className="mb-3">
                                        <Input
                                            label="Delivery Date"
                                            name="deliveryDate"
                                            placeholder="Delivery Date"
                                            form={form}
                                            type="date"
                                            value={form.values.deliveryDate}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <div className="mb-3">
                                        <Input
                                            label="Hour Meter"
                                            afterLabel=" (if applicable)"
                                            name="hourMeter"
                                            placeholder="Hour Meter"
                                            form={form}
                                            value={form.values.hourMeter}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={12}>
                                    <div className="mb-3">
                                        <Select
                                            label="Was this machine purchased New or Used?"
                                            name="isNew"
                                            placeholder="Was this machine purchased New or Used?"
                                            formik={form}
                                            options={newOrUsedOptions}
                                            value = {
                                                newOrUsedOptions.filter(option =>
                                                    option.value === form.values.isNew)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} className="font-size-18 fw-bold pb-3 text-uppercase">
                                    Delivery Information
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Was there any shipping or transport damage?"
                                            name="hasShippingOrTransportDamage"
                                            form={form}
                                            value={form.values.hasShippingOrTransportDamage}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Was the Tool Kit complete?"
                                            afterLabel=" (if applicable)"
                                            name="isToolKitComplete"
                                            form={form}
                                            value={form.values.isToolKitComplete}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Did you receive an Operator's Manual with Safety Instructions?"
                                            name="hasManual"
                                            form={form}
                                            value={form.values.hasManual}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Were all Options ordered installed?"
                                            name="allOptionsInstalled"
                                            form={form}
                                            value={form.values.allOptionsInstalled}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Did the Dealer review Maintenance Procedures?"
                                            name="dealerReviewMaintenanceProcedures"
                                            form={form}
                                            value={form.values.dealerReviewMaintenanceProcedures}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div className="mb-3">
                                        <InputRadio
                                            label="Did the Dealer review Safety Procedures?"
                                            name="dealerReviewSafetyProcedures"
                                            form={form}
                                            value={form.values.dealerReviewSafetyProcedures}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-center mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-danger save-user"
                                            disabled={isRegistrationLoading || isButtonDisabled}
                                        >
                                            SUBMIT
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </CardBody>
            </Card>
        </>
    );
};

DeviceInformationForm.propTypes = {
    deviceSerialNumber: PropTypes.string.isRequired,
    isRegistrationLoading: PropTypes.bool.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

export default DeviceInformationForm;
