import PropTypes from 'prop-types';

// Import scss
import "./assets/scss/theme.scss";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppRoutes from "./AppRoutes";


// MSAL configuration
const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_B2C_AUTHORITY_URL,
    navigateToLoginRequestUrl: false,
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const pca = new PublicClientApplication(configuration);

const App = () => {

  return (
    <MsalProvider instance={pca}>
      <AppRoutes />
    </MsalProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;