export const GET_DEVICE_LIST = "GET_DEVICE_LIST";
export const GET_MY_DEVICE_LIST = "GET_MY_DEVICE_LIST";
export const SET_DEVICE_LIST_SUCCESS = "SET_DEVICE_LIST_SUCCESS";
export const SET_DEVICE_LIST_FAIL = "SET_DEVICE_LIST_FAIL";

export const GET_DEVICE = "GET_DEVICE";
export const SET_DEVICE_SUCCESS = "SET_DEVICE_SUCCESS";
export const SET_DEVICE_FAIL = "SET_DEVICE_FAIL";

export const ADD_DEVICE = "ADD_DEVICE";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL";

export const ADD_DEVICE_AS_OWNER = "ADD_DEVICE_AS_OWNER";
export const ADD_DEVICE_AS_OWNER_SUCCESS = "ADD_DEVICE_AS_OWNER_SUCCESS";
export const ADD_DEVICE_AS_OWNER_FAIL = "ADD_DEVICE_AS_OWNER_FAIL";

export const TOGGLE_DELETE_DEVICE_CONFIRMATION = "TOGGLE_DELETE_DEVICE_CONFIRMATION";

export const DELETE_DEVICE = "DELETE_DEVICE";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAIL = "DELETE_DEVICE_FAIL";

export const TOGGLE_SHOW_DEVICE_FORM = "TOGGLE_SHOW_DEVICE_FORM";

export const TOGGLE_SHOW_ASSIGN_OWNER_FORM = "TOGGLE_SHOW_ASSIGN_OWNER_FORM";

export const ASSIGN_DEVICE_OWNER = "ASSIGN_DEVICE_OWNER";
export const ASSIGN_DEVICE_OWNER_SUCCESS = "ASSIGN_DEVICE_OWNER_SUCCESS";
export const ASSIGN_DEVICE_OWNER_FAIL = "ASSIGN_DEVICE_OWNER_FAIL";

export const REGISTER_MY_DEVICE = "REGISTER_MY_DEVICE";
export const REGISTER_MY_DEVICE_SUCCESS = "REGISTER_MY_DEVICE_SUCCESS";
export const REGISTER_MY_DEVICE_FAIL = "REGISTER_MY_DEVICE_FAIL";

export const GET_DEVICE_REGISTRATION_STATUS = "GET_DEVICE_REGISTRATION_STATUS";
export const GET_DEVICE_REGISTRATION_STATUS_SUCCESS = "GET_DEVICE_REGISTRATION_STATUS_SUCCESS";
export const GET_DEVICE_REGISTRATION_STATUS_FAIL = "GET_DEVICE_REGISTRATION_STATUS_FAIL";
