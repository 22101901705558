import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from './Common/withRouter';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { changeLayoutMode } from 'store/actions';
import logoSvg from 'assets/svg/fecon-logo.svg';

const NonAuthLayout = (props) => {
  const dispatch = useDispatch();
  const LayoutModeProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      layoutModeType: layout.layoutModeType,
    })
  );

  const {
    layoutModeType
  } = useSelector(LayoutModeProperties);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  return (
    <div style={{backgroundColor: "#000", minHeight: "100vh"}}>
      {/*<div style={{ padding: '46px'}}>
        <img src={logoSvg} alt="" height="40" />
      </div>*/}
      {props.children}
    </div>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(NonAuthLayout);
